<template>
    <div class="main_button">
        <v-btn
            @click="$emit('click')"
            :class="addButtonStyles"
            :disabled="disabled"
            :loading="loading"
            >
            <slot></slot>
        </v-btn>
    </div>
</template>
<script>
export default {
    name: "MainButton",
    props: {
        disabled: Boolean,
        loading: Boolean
    },
    data(){
        return {

        }
    },
    computed: {
        addButtonStyles(){
            return this.$root.appType('cash') || this.$root.appType('nordwind') || this.$root.appType('bankiros') ? 'rectangle-button' : `${this.$root.state.type()}-button`
        }
    },

}
</script>

<style scoped lang="scss">
.main_button {
    margin-top: 32px;
}
</style>
