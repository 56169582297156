// Спрягает числительное
// var rubbles = declOfNum(['Рубль','Рубля','Рублей']);
// rubbles(234); - return Рубля
var declOfNum = (function () {
    var cases = [2, 0, 1, 1, 1, 2];
    var declOfNumSubFunction = function (titles, number) {
        number = Math.abs(number);
        return titles[(number % 100 > 4 && number % 100 < 20) ? 2 : cases[(number % 10 < 5) ? number % 10 : 5]];
    };
    return function (_titles) {
        if (arguments.length === 1) {
            return function (_number) {
                return declOfNumSubFunction(_titles, _number)
            }
        } else {
            return declOfNumSubFunction.apply(null, arguments)
        }
    }
})();

export function year(number) {
    return declOfNum(['год', 'года', 'лет'], number);
}

export function month(number) {
    return declOfNum(['месяц', 'месяца', 'месяцев'], number)
}

export function day(number) {
    return declOfNum(['день', 'дня', 'дней'], number);
}

export function rubble(number) {
    return declOfNum(['рубль', 'рубля', 'рублей'], number);
}