<template>
  <!--block5-->
    <div>
        <div
            v-if="$root.appType('cash') || $root.appType('nordwind')"
            class="block-5 block-5_bg">
            <div class="container-width">
                <div class="block-5-body">
                    <h1
                        class="block-5-body_title"
                        >Частые вопросы
                    </h1>
                <template>
                    <Accordion
                        :accordions="otherAccordions"
                        :class="$root.appType('vtb') ? 'vtb-accordion' : ''"
                        class="mb-6"
                        icon="mdi-chevron-down"
                        color="red"
                        dark
                    />
                </template>
                <FooterHTML />
                </div>
            </div>
        </div>

        <div
            v-if="$root.appType('vtb')"
            class="vtb_bg">
            <div class="container-width">
                <div class="vtb_block-5-body">
                    <h1
                        class="vtb_block-5_title"
                        >Частые вопросы
                    </h1>
                    <template>
                        <Accordion
                            :accordions="vtbAccordions"
                            :class="$root.appType('vtb') ? 'vtb-accordion' : ''"
                            class="mb-6"
                            icon="mdi-chevron-down"
                            color="red"
                            dark
                        />
                    </template>
                    <FooterHTML />
                </div>
            </div>
        </div>

        <div
            v-if="$root.appType('travelata')"
            class="travelata_bg">
            <div class="container-width">
                <div class="travelata_block-5-body">
                    <h1
                        class="travelata_block-5_title"
                        >Частые вопросы
                    </h1>
                    <template>
                        <Accordion
                            :accordions="vtbAccordions"
                            :class="$root.appType('travelata') ? 'travelata-accordion' : ''"
                            class="mb-6"
                            icon="mdi-chevron-down"
                            color="red"
                            dark
                        />
                    </template>
                    <FooterHTML />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Accordion from '@/components/global/Accordion.vue'
import FooterHTML from "@/components/html/FooterHTML";

export default {
  components: { Accordion, FooterHTML },
  name: "BlockHTML5",
  data: () => ({
    otherAccordions: [
      {
        id: 1,
        action: false,
        itemPanel: 'Как можно вернуть проценты по кредиту*?',
        bodyPanel: 'Совершите ежемесячные платежи, кроме последнего в соответствии с графиком и в срок, установленный условиями акции.  \n' +
            'После списания предпоследнего платежа в течение 14 дней направьте письмо на адрес: info@paylate.info, с номером лицевого счета/аккаунта и словами «Акция «Заём 0%» в теме письма. \n' +
            'В течение 7-ми дней сумма начисленных и уплаченных процентов за весь период пользования заёмных средств поступит на баланс вашего лицевого счета.\n' +
            'Зачисленная сумма может быть использована для будущих покупок через сервис «Paylate – Доверительная оплата» или перечислена на ваш счёт в банке РФ. \n' +
            `Полные условия акции <a target="_blank" href="./docs/loan0.pdf">тут</a>.`
      },
      {
        id: 2,
        action:true,
        itemPanel: 'Как быстро я получу деньги?',
        bodyPanel: 'Мы отправим деньги после подписания договора:\n' +
            '- переводы через СБП (по номеру телефона) осуществляются в течение нескольких минут;\n' +
            '- переводы на банковскую карту могут занимать до нескольких часов;\n' +
            '- переводы по банковским реквизитам могут занять до 24 часов, но как правило операции проходят гораздо быстрее.'
      },
      {
        id: 3,
        action:true,
        itemPanel: 'Какой размер ставки по кредиту*?',
        bodyPanel: 'ПСК для нецелевого займа может находиться в диапазоне от 0% до 292%. Процентная ставка за использование заемных средств будет рассчитываться индивидуально, исходя из кредитных показателей клиента, и составлять от 1,9% в месяц от суммы первоначального займа. После подачи заявки система определит точную процентную ставку.'
      },
      {
        id: 4,
        action:true,
        itemPanel: 'На какие цели я могу взять деньги?',
        bodyPanel: 'Средства предоставляются на любые цели, используйте их на ваше усмотрение.'
      },
      {
        id: 5,
        action:true,
        itemPanel: 'Способы погашения кредита*',
        bodyPanel: `- банковской картой: VISA, MasterCard, Мир;
                    - электронный кошелек: терминалы Элекснет/online кабинет;
                    - наличными без комиссии: в банкоматах и в отделениях банков.
                  `
      },
      {
        id: 6,
        action:true,
        itemPanel: 'Можно ли погасить кредит* досрочно?',
        bodyPanel: 'Да, вы можете досрочно погасить всю сумму на любом этапе. При полном погашении кредита<sup class="other">*</sup> в льготный период проценты не оплачиваются или оплачиваются по льготной процентной ставке, в остальных случаях переплата взимается за фактический срок пользования кредита<sup class="other">*</sup> .'
      },
    ],
    vtbAccordions: [
      {
        id: 1,
        action: false,
        itemPanel: 'Как вернуть проценты по кредиту*?',
        bodyPanel: 'Возврат процентов по кредиту возможен по договорам со сроком 3, 6, 10 или 12 мес. \n' +
            'Совершите ежемесячные платежи, кроме последнего в соответствии с графиком и в срок, установленный условиями акции.  \n' +
            'После списания предпоследнего платежа в течение 14 дней направьте письмо на адрес: info@paylate.info, с номером лицевого счета/аккаунта и словами «Акция «Заём 0%» в теме письма. \n' +
            'В течение 7-ми дней сумма начисленных и уплаченных процентов за весь период пользования заёмных средств поступит на баланс вашего лицевого счета.\n' +
            'Зачисленная сумма может быть использована для будущих покупок через сервис «Paylate – Доверительная оплата» или перечислена на ваш счёт в банке РФ. \n' +
            `Полные условия акции <a class="vtb-accordion_link"  target="_blank" href="./docs/loan0.pdf">тут</a>.`
      },
      {
        id: 2,
        action:true,
        itemPanel: 'Как быстро я получу деньги?',
        bodyPanel: 'Мы отправим деньги после подписания договора:\n' +
            '- переводы через СБП (по номеру телефона) осуществляются в течение нескольких минут;\n' +
            '- переводы на банковскую карту могут занимать до нескольких часов;\n' +
            '- переводы по банковским реквизитам могут занять до 24 часов, но как правило операции проходят гораздо быстрее.'
      },
      {
        id: 3,
        action:true,
        itemPanel: 'Какой размер ставки по кредиту*?',
        bodyPanel: 'Процентная ставка за использование заемных средств будет рассчитываться индивидуально, исходя из кредитных показателей клиента, и составлять от 1,9% в месяц от суммы первоначального займа. После подачи заявки система определит точную процентную ставку. Полная стоимость кредита* для нецелевого займа может находиться в диапазоне от 0% до 292%.'
      },
      {
        id: 4,
        action:true,
        itemPanel: 'На какие цели я могу потратить деньги?',
        bodyPanel: 'Средства предоставляются на любые цели, используйте их на ваше усмотрение.'
      },
      {
        id: 5,
        action:true,
        itemPanel: 'Способы погашения кредита*',
        bodyPanel: `- банковской картой: VISA, MasterCard, Мир;
                    - электронный кошелек: терминалы Элекснет/online кабинет;
                    - наличными без комиссии: в банкоматах и в отделениях банков.
                  `
      },
      {
        id: 6,
        action:true,
        itemPanel: 'Можно ли погасить кредит* досрочно?',
        bodyPanel: 'Да, вы можете досрочно погасить всю сумму на любом этапе. При полном погашении кредита<sup class="other">*</sup> в льготный период проценты не оплачиваются или оплачиваются по льготной процентной ставке, в остальных случаях переплата взимается за фактический срок пользования кредита<sup class="other">*</sup> .'
      },
    ]
  }),
}
</script>

<style lang="scss" >
@import "@/assets/scss/_vtb-variables.scss";
@import "@/assets/scss/_travelata-variables.scss";

.block-5 {
  &_bg {
    background-color: #0a0a0d;
    border-top-left-radius: 24px;
    border-top-right-radius: 24px;
    background: radial-gradient(
            44.03% 44.03% at 50% 55.97%,
            #282828 0%,
            #0a0a0d 79.21%,
            #0a0a0d 100%
    );
  }

    .accordion-body-item {
        padding: 7px 0px !important;

        a {
            color: #ff0000 !important;
            text-decoration: none !important;
        }

    }

    &-body {
        padding: 105px 0 40px 0;

        &_title {
            font-family: NerisBold;
            font-style: normal;
            font-weight: 600;
            font-size: 48px;
            line-height: 48px;
            text-align: center;
            margin-bottom: 60px;
        }

        .v-expansion-panels {
            padding: 0 25px;

            .v-expansion-panel {
                min-height: 44px;
                font-family: Neris;
                font-weight: 600;
                font-size: 18px;
                margin-top: 20px;
                color: white;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: flex-start;
                position: relative;
                transition: height 0.3s;
                padding: 0px 20px 0px 24px;
                border-radius: 16px;
                background: transparent;
                border: 1px solid rgba(255, 255, 255, 0.115);
                background: radial-gradient(
                        100% 100% at 0% 0%,
                        rgba(255, 255, 255, 0.075) 0%,
                        rgba(255, 255, 255, 0.0225) 100%
                );

                &::after {
                    content: "";
                    display: none;
                }

                button.v-expansion-panel-header {
                    font-family: Neris;
                    font-style: normal;
                    font-weight: 600;
                    border-radius: 16px;
                    font-size: 18px;
                    line-height: 24px;
                    padding-left: 0;
                    padding-right: 0;

                    .v-icon {
                        color: #656565;
                    }
                }

                &--active {
                    button.v-expansion-panel-header {
                        border-bottom: 1px solid #333333;
                        border-radius: 16px 16px 0px 0px;
                    }
                }

                .v-expansion-panel-content__wrap {
                    padding: 8px 0 16px 0;
                    font-family: Roboto;
                    font-weight: 300;
                    font-size: 16px;
                    line-height: 1.6em;
                    display: flex;
                    align-items: center;
                    color: #ffffff;
                }

                p {
                    font-family: Roboto;
                    font-style: normal;
                    font-weight: 300;
                    font-size: 16px;
                    line-height: 24px;
                    margin: 0;
                    padding: 25px 11px;
                }

                ul {
                    padding: 0 10px;

                    li {
                        display: flex;
                        flex-direction: row;
                        justify-content: flex-start;
                        list-style: none !important;

                        span:nth-child(1) {
                            margin-right: 5px;
                        }
                    }
                }
            }
        }
    }

}

.vtb {
  &_bg {
    background-color: #2F3441;
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
  }
  &_block-5_title {
    @include big-text;
    color: #fff;
    text-align: center;
    margin-bottom: 50px;


  }

  &_block-5-body {
        padding: 105px 0 40px 0;

        &_title {
            font-family: NerisBold;
            font-style: normal;
            font-weight: 600;
            font-size: 48px;
            line-height: 48px;
            text-align: center;
            margin-bottom: 60px;
        }

        .v-expansion-panels {
            padding: 0 25px;
            gap: 10px;

            .v-expansion-panel {
                min-height: 44px;

                color: white;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: flex-start;

                position: relative;
                transition: height 0.3s;
                padding: 0px 20px 0px 24px;
                border-radius: 8px;
                background: transparent;
                border: 1px solid rgba(255, 255, 255, 0.115);
                background: radial-gradient(
                        100% 100% at 0% 0%,
                        rgba(255, 255, 255, 0.075) 0%,
                        rgba(255, 255, 255, 0.0225) 100%
                );

                &::after {
                    content: "";
                    display: none;
                }

                button.v-expansion-panel-header {

                    border-radius: 16px;

                    padding-left: 0;
                    padding-right: 0;

                    .v-icon {
                        color: #656565;
                    }
                }

                &--active {
                    button.v-expansion-panel-header {
                        border-bottom: 1px solid #333333;
                        border-radius: 16px 16px 0px 0px;
                    }
                }

                .v-expansion-panel-content__wrap {
                    padding: 8px 0 16px 0;
                    font-family: Roboto;
                    font-weight: 300;
                    font-size: 16px;
                    line-height: 1.6em;
                    display: flex;
                    align-items: center;
                    color: #ffffff;
                }

                p {
                    font-family: Roboto;
                    font-style: normal;
                    font-weight: 300;
                    font-size: 16px;
                    line-height: 24px;
                    margin: 0;
                    padding: 25px 11px;
                }

                ul {
                    padding: 0 10px;

                    li {
                        display: flex;
                        flex-direction: row;
                        justify-content: flex-start;
                        list-style: none !important;

                        span:nth-child(1) {
                            margin-right: 5px;
                        }
                    }
                }
            }
        }
    }

  &-accordion {
    &_link {
      font-family: Roboto;
      font-weight: 300;
      font-size: 16px;
      line-height: 1.6em;
      color: #ffffff !important;
    }
    & .v-expansion-panel-header {
      @include medium-text;

      .v-icon {
          color: #656565;
      }
    }
  }
}

.travelata {
    &_bg {
        background-color: #293033;
        border-top-left-radius: 4px;
        border-top-right-radius: 4px;
    }
    &_block-5_title {
        @include tr-text(48px, 800, 56px, -0.5px);
        font-family: 'OpenSansEB';
        color: #fff;
        text-align: center;
        margin-bottom: 64px;

        @media (max-width: 600px) {
            font-size: 36px !important;
        }
        @media (max-width: 450px) {
            @include tr-text(24px, 800, 28px, -0.4px);
        }
    }

    &_block-5-body {
        padding: 128px 0 28px 0;
        @media (max-width: 600px) {
            padding: 64px 0 28px 0;
        }
        //&_title {
        //    font-family: NerisBold;
        //    font-style: normal;
        //    font-weight: 600;
        //    font-size: 48px;
        //    line-height: 48px;
        //    text-align: center;
        //    margin-bottom: 60px;
        //}

        .v-expansion-panels {
            padding: 0 25px;
            gap: 16px;

            .v-expansion-panel {
                min-height: 44px;

                color: white;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: flex-start;

                position: relative;
                transition: height 0.3s;
                padding: 0px 20px 0px 24px;
                border-radius: 8px;
                background: transparent;
                border: 1px solid rgba(255, 255, 255, 0.115);
                background: radial-gradient(
                        100% 100% at 0% 0%,
                        rgba(255, 255, 255, 0.075) 0%,
                        rgba(255, 255, 255, 0.0225) 100%
                );

                &::after {
                    content: "";
                    display: none;
                }

                button.v-expansion-panel-header {

                    border-radius: 16px;

                    padding-left: 0;
                    padding-right: 0;

                    .v-icon {
                        color: #656565;
                    }
                }

                &--active {
                    button.v-expansion-panel-header {
                        border-bottom: 1px solid #333333;
                        border-radius: 16px 16px 0px 0px;
                    }
                }

                .v-expansion-panel-content__wrap {
                    padding: 8px 0 16px 0;
                    font-family: Roboto;
                    font-weight: 300;
                    font-size: 16px;
                    line-height: 1.6em;
                    display: flex;
                    align-items: center;
                    color: #ffffff;
                }

                p {
                    font-family: Roboto;
                    font-style: normal;
                    font-weight: 300;
                    font-size: 16px;
                    line-height: 24px;
                    margin: 0;
                    padding: 25px 11px;
                }

                ul {
                    padding: 0 10px;

                    li {
                        display: flex;
                        flex-direction: row;
                        justify-content: flex-start;
                        list-style: none !important;

                        span:nth-child(1) {
                            margin-right: 5px;
                        }
                    }
                }
            }
        }
    }

    &-accordion {
        &_link {
            font-family: Roboto;
            font-weight: 300;
            font-size: 16px;
            line-height: 1.6em;
            color: #ffffff !important;
        }
        & .v-expansion-panel-header {
            @include tr-text(18px, 600);
            color: #fff;
            font-family: 'OpenSansSB';
            @media (max-width: 450px) {
                font-size: 16px !important;
            }
            .v-icon {
                color: #656565;
            }
        }
    }
}
</style>
