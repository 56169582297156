<template>
    <div :style="{overflow: anketa.isOpenModal ? 'hidden' : ''}">
        <BankirosHeader/>
        <BankirosHero/>
        <BankirosCashback v-if="anketa.actionInfo"/>
        <BankirosQuestions/>

        <BankirosFooter/>
    </div>
</template>
<script>
import '@/assets/scss/style.scss';
//   import BlockHTML6 from "@/components/html/BlockHTML6";
import BankirosHeader from "@/components/bankiros/Header.vue";
import BankirosHero from "@/components/bankiros/Hero.vue";
import BankirosCashback from "@/components/bankiros/Cashback.vue";
import BankirosQuestions from "@/components/bankiros/Questions.vue";
import BankirosFooter from "@/components/bankiros/Footer.vue";
//   import SMSInfo from "@/components/SMSInfo";

export default {
    name: "BankirosMainPage",
    components: {
        BankirosFooter,
        BankirosQuestions,
        BankirosCashback,
        BankirosHero,
        BankirosHeader,
        //   BlockHTML6,
        //   SMSInfo,
    },
    data: () => ({
        trigger: false
    }),
    computed: {
        anketa() {
            return this.$root.anketa;
        },
    },
};
</script>

<style lang="scss">
.bankiros {
    &_outer {
        background-color: #F3F7FA;
    }

    &_container {
        max-width: 1760px;
        width: 100%;
        margin: 0 auto;
    }

    &_header {
        width: 100%;
        height: 80px;
        background-color: #232229;
        border-radius: 8px;
        margin-bottom: 20px;

        &_inner {
            max-width: 1100px;
            width: 100%;
            margin: 0 auto;
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 20px 0 0;

            @media (max-width: 1150px) {
                padding: 20px 20px 0;
            }

            &_telephone {
                font-family: VtbMedium;
                color: #fff;
                text-decoration: none;
                font-weight: 500;
                font-size: 18px;
                line-height: 18px;
                letter-spacing: -0.4px;
            }
        }
    }
}
</style>

