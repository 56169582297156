<template>
  <body :style="{overflow: anketa.isOpenModal ? 'hidden' : ''}">
    <!--Слайдер или отображение фразы - отказ-->
    <TemplateSlider
      v-if="urlParams && anketa.sliderParams"
      :reRenderComponent="trigger"
    />
    <SMSInfo v-else />
    <BlockHTML6 />
    <BlockHTML4 v-if="anketa.actionInfo" />
    <BlockHTML5 />

  </body>
</template>
<script>
import '@/assets/scss/style.scss';
import TemplateSlider from "@/components/TemplateSlider";
import BlockHTML4 from "@/components/html/BlockHTML4";
import BlockHTML5 from "@/components/html/BlockHTML5";
import BlockHTML6 from "@/components/html/BlockHTML6";
import SMSInfo from "@/components/SMSInfo";

export default {
  name: "MainPage",
  components: {
    TemplateSlider,
    BlockHTML6,
    BlockHTML4,
    BlockHTML5,
    SMSInfo,
  },
  data: () => ({
    trigger: false
  }),
  methods: {
    changeTrigger() {
      this.trigger = !this.trigger;
    },
  },
  computed: {
    connector() {
      return this.$root.connector;
    },
    anketa() {
      return this.$root.anketa;
    },
    urlParams: {
      get() {
        let urlParams = new URLSearchParams(window.location.search);
        return urlParams.has('s');
      },
    },
  },
};
</script>

<style lang="scss" scoped>

// @import '../assets/scss/style.scss';

</style>
