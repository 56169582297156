<template>
<!--  <p class="mb-0">-->
<!--    {{ label }} <span>{{ timerValue }} {{ append }}</span>-->
<!--  </p>-->
  <div>
      <span>{{label}}</span>
      <span
          :class="$root.appType('travelata') ? 'travelata_timer_timer-value' : 'timer_timer-value'"
          class="timer_timer-value_color"
        >{{timerValue}}
      </span>
      <span
          :class="$root.appType('travelata') ? 'travelata_timer_append' : 'timer_append'"
          class="timer_append_color"
        >{{append}}
      </span>
  </div>
</template>

<script>
export default {
    props: {
        label: String,
        seconds: Number,
        append: String,
        colorTimerValue: {
            type: String,
        },
        colorAppend: {
            type: String,

        }
    },
    mounted() {
        this.timerValue = this.seconds || 0;
        this.interval = setInterval(this.decrease, 1000);
    },
    data: () => ({
        interval: null,
        timerValue: 0,
    }),
    methods: {
        decrease() {
            if (this.timerValue > 0) {
                this.timerValue--
            } else {
                clearInterval(this.interval);
                this.$emit('complete');
            }
        },
    },


}
</script>

<style lang="scss" scoped>
.timer_timer-value {
    margin: 2px;
}
.travelata {
    &_timer_timer-value {
        color: #059BCE;
        margin-left: 2px;
    }

    &_timer_append {
        margin-left: 2px;
        color: #059BCE;
    }
}
.timer_timer-value_color {
    color: v-bind(colorTimerValue);
}
.timer_append_color {
    color: v-bind(colorAppend);
}
</style>
