import moment from "moment";
import state from "./state";

function checkDigitsCount(value, length) {
    let val = "";
    if (value) val = value;

    return val.replace(/\D/g, '').length == length;
}

// добавляем в поля с fieldType=file
let fileFieldLoadingAttributes = {
    value: {},
    isUploaded: false,
    uploading: false,
    success: false,
    errorMessage: '',
    alertMessage: '',
    send(method, params) {
        this.errorMessage = '';
        this.alertMessage = '';

        method(params)
            .then(() => this.uploaded())
            .catch(() => this.uploadingError());
    },
    uploaded() {
        this.uploading = false;
        this.success = true;

        this.isUploaded = true;
    },
    uploadingError() {
        this.errorMessage = "Ошибка отправки";
        this.uploading = false;
        this.success = false;

        this.isUploaded = false;
    },
    validate() {
        if (!this.isUploaded) {
            this.value = {};
            this.alertMessage = 'Файл не загружен';
        }
        return this.isUploaded;
    }
};
// Правила получения очищенных значений полей
let cleanRules = {
    phone(str) {
        return str.replace("+", "").replace(/\s+/g, "");
    },
    workPhone(str) {
        let newString = str.replace("+", "").replace(/\s+/g, "");

        if (newString.length === 1) {
            newString = ''
        }
        console.log('workPhone newString', newString)

        return newString
    },
    dateValue(str) {
        const newStr = str;

        if (str?.length) {
            newStr.split("").reverse().join("").replace(/\./, "-");
        }

        return newStr;
    },
    // birthdate(value, date) {
    //     const min = 20;
    //     const max = 44;
    //     const age = makeMoment(date);
    //
    //     if (age >= min && age <= max) {
    //         return makeMoment(value) <= age - 20;
    //     } else if (age > max) {
    //         return makeMoment(value) <= age - 45;
    //     }
    // },
    phoneFirstDigitIs9(str) {
        return str.replace("+7", "").replace(/\s+/g, "");
    },
    passport(str) {
        return (
            str.replace(/[ -]/g, "").match(/^[0-9]{4}/g)[0] +
            " " +
            str.match(/[0-9]{6}/g)
        );
    },
    getDigits(str) {
        return str.replace(/\D/g, "");
    },
    withoutSpace(str) {
        return str.replace(/\s+/g, "");
    },
};

function getYears(date) {
    if (/\d{2}\.\d{2}\.\d{4}/.test(date)) {
        return new Date().getFullYear() - moment(date, "DD.MM.YYYY").year();
    }

    if (/\d{4}-\d{2}-\d{2}/.test(date)) {
        return new Date().getFullYear() - moment(date, "YYYY-MM-DD").year();
    }

    return {isValid: () => false};
}

function makeMoment(date) {
    if (/\d{2}\.\d{2}\.\d{4}/.test(date)) {
        return new Date().getFullYear() - moment(date, "DD.MM.YYYY").year();
    }

    if (/\d{4}-\d{2}-\d{2}/.test(date)) {
        return new Date().getFullYear() - moment(date, "YYYY-MM-DD").year();
    }

    return {isValid: () => false};
}

let VR = {
    required: (value) => !!value || "Заполните",
    requiredSelect: (obj) => !!obj.value || "Выберите из списка",
    email: (
        value,
        pattern = /^(([^а-яА-ЯёЁ<>()[\]\\.,;:\s@"]+(\.[^а-яА-ЯёЁ<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    ) => pattern.test(value) || "Введите корректный e-mail",
    phoneFirstDigitIs9: (value, pattern = /^(\+7 9)./) =>
        pattern.test(value) || "Номер должен начинаться с 9",
    correctAccountNumber: (value, pattern = /^(4081) ./) =>
        pattern.test(value) || "Номер счета введен не верно",
    validCard: (value) => {
        if (value.length < 1) return false;
        //Принимаем только цифры
        if (/[^0-9-\s]+/.test(value)) return false;
        //проверка по алгоритму Луна
        let nCheck = 0, bEven = false;
        value = value.replace(/\D/g, "");

        for (let n = value.length - 1; n >= 0; n--) {
            let cDigit = value.charAt(n),
                nDigit = parseInt(cDigit, 10);

            if (bEven) {
                if ((nDigit *= 2) > 9) nDigit -= 9;
            }

            nCheck += nDigit;
            bEven = !bEven;
        }

        return (nCheck % 10) === 0 || "Неверный номер карты";
    },
    phone: (value) =>
        checkDigitsCount(value, 11) || "Номер введен не полностью",
    workPhone: (value) => {
        console.log('vr workPhone value', value)
        // value = value.trim()
        if (value === '+7' || value === '') {
            console.log('VR workPhone 1')
            return true
        } else {
            console.log('VR workPhone 2')
            return checkDigitsCount(value, 11) || "Номер введен не полностью"

        }


    },
    accountNumber: (value) =>
        checkDigitsCount(value, 20) || "Номер счета введен не полностью",
    accountCard: (value) =>
        checkDigitsCount(value, 16) || "Номер карты введен не полностью",
    code: (value) =>
        checkDigitsCount(value, 5) || "СМС код введен не полностью",
    smsCode: (value) =>
        checkDigitsCount(value, 4) || "СМС код введен не полностью",
    date: (value) =>
        (value.split(".")[0] <= 31 && value.split(".")[1] <= 12) ||
        "Дата введена некорректно",
    dateCount: (value) =>
        checkDigitsCount(value, 8) || "Дата введена не полностью",
    shortDate: (value) =>
        checkDigitsCount(value, 6) || "Дата введена не полностью",
    passport: (value) =>
        checkDigitsCount(value, 10) ||
        "Серия номер паспорта введены не полностью",
    divisionCode: (value) =>
        checkDigitsCount(value, 6) || "Код подразделения введен не полностью",
    signNumber: (value) =>
        checkDigitsCount(value, 5) || "Код введен не полностью",
    address: (value) => {
        return checkDigitsCount(value, 5) || "Код введен не полностью";
    },
    fio: (value) => {
        let val = value?.value || value;
        return val?.split(" ")?.length >= 2 || "Введите полное ФИО";
    },

    birthdate: {
        min: 18,
        max: 70,
        limitations: (value) => {
            const date = makeMoment(value);

            return (
                (date > VR.birthdate.min && date < VR.birthdate.max) ||
                "Допустим возраст от 18 до 70 лет"
            );
        },
    },

    // incomeNumbers: (value, patern = /[0-9-\s]+/) => {
    //     // if (/[^0-9-\s]+/.test(value)) return false;
    //     // (value, pattern = /^(\+7 9)./) =>
    //     //     pattern.test(value) || "Номер должен начинаться с 9",
    //     return patern.test(value) || 'Ошибка, введите число!!!'
    // }
};

// function yearsBefore(years) {
//   return moment(Date.now()).add(-years, "years").add(-1, "days").year();
// }
// function isNordwind(){
//     return state.state.isNordwind()
// }

// console.log('anketa state', state.data().state.addYandexMetricGoals())

// Поля анкеты
export default {
    data() {
        return {
            anketa: {
                sliderParams: true,
                isOpenModal: false,
                withoutToken: false,

                fullScreenModal: false,
                registration: false,

                // client_id: "1106964",
                // token_timestamp: "2022-06-15T12:39:40",
                // new_client_token: "26c538cfddd78a3cd886984f4b4b2f96",

                mindboxId: "",

                client_id: "",
                new_client_token: "",
                token_timestamp: "",
                //форма Паспорт (Passport)
                /* Как определять атрибуты поля?
                 *
                 * fieldType - устанавливает тип поля, отрисовка в компоненте Field
                 * rules и другие атрибуты соответствуют атрибутам компонент Vuetify (https://vuetifyjs.com)
                 * mask - https://www.npmjs.com/package/v-mask
                 * isMoney, isCyrilic, isCyrilicIssued, isCyrilicPlace, isEngEmail - типы ввода обрабатывающиеся компонентой FiledText
                 * type: "tel" - используется для отображения цифровой клавиатуры в мобильном
                 * input() - унифицированный метод реакции на ввод в поле*/
                phone: {
                    label: "Ваш мобильный телефон",
                    value: '',
                    inputmode: 'numeric',
                    mask: '+7 ### ### ## ##',
                    placeholder: '+7 ',
                    isPhone: true,
                    cleanValue() {
                        return cleanRules.phone(this.value);
                    },
                    valueFirstDigitIs9() {
                        return cleanRules.phoneFirstDigitIs9(this.value);
                    },
                    grid: {
                        cols: 12,
                        xl: 12,
                        lg: 12,
                        md: 12,
                        sm: 12,
                        xs: 12
                    },
                    rules: [VR.required, VR.phoneFirstDigitIs9, VR.phone],
                    disabled: false,
                    type: "tel",
                    valueWithMask() {
                        console.log(this.cleanValue())
                        let str = this.cleanValue().match(/(\d)(\d{3})(\d{3})(\d{2})(\d{2})/);
                        let result = str[1] + ' ' + str[2] + ' ' + str[3] + ' ' + str[4] + str[5];

                        console.log('result', result)
                        return result
                    }
                },
                password: {
                    value: "",
                    type: "tel",
                    label: "Ваш пароль",
                    placeholder: "Введите ваш пароль",
                    mask: "",
                    autocomplete: "off",
                    hideValue: true,
                    rules: [VR.required],
                },
                fio: {
                    value: "",
                    fieldType: "flexible",
                    grid: {
                        cols: 12,
                        xl: 12,
                        lg: 12,
                        md: 12,
                        sm: 12,
                        xs: 12
                    },
                    name: "",
                    middleName: "",
                    lastName: "",
                    fields: [
                        {
                            isMobile: false,
                            rules: [VR.fio],
                            value: "",
                            label: "ФИО",
                            placeholder: "Фамилия Имя Отчество",
                            fieldType: "autocomplete",
                            items: [],
                            "item-text": "value",
                            grid: {
                                cols: 12,
                                md: 12,
                            },
                            visible: false,
                            isCyrilicPlace: true,

                            input: (value) => {
                                this.connector
                                    .getDadataFio(value)
                                    .then((data) => {
                                        let suggestions = data.suggestions
                                            .filter(
                                                (item) => !!item.data.surname
                                            )
                                            .map((item) => ({
                                                value: [
                                                    item.data.surname,
                                                    item.data.name,
                                                    item.data.patronymic,
                                                ]
                                                    .join(" ")
                                                    .trim(),
                                            }));

                                        this.anketa.fio.fields[0].items =
                                            suggestions;
                                    });

                                if (value && value?.split(" ").length >= 2) {
                                    this.anketa.fio.name = value.split(" ")[1];
                                    this.anketa.fio.middleName =
                                        value.split(" ")[2];
                                    this.anketa.fio.lastName =
                                        value.split(" ")[0];
                                }
                            },
                        },
                        {
                            value: "",
                            label: "Фамилия",
                            placeholder: "Укажите фамилию",
                            items: [""],
                            visible: false,
                            fieldType: "autocomplete",
                            "item-text": "value",

                            rules: [VR.required],
                            key: "surname",
                            isMobile: true,
                            input: (value) => {
                                this.connector
                                    .getDadataFio(value, "surname")
                                    .then((data) => {
                                        let suggestions = data.suggestions;

                                        this.anketa.fio.fields[1].items = [
                                            {value},
                                            ...suggestions,
                                        ];
                                    });

                                this.anketa.fio.lastName = value;
                            },
                        },
                        {
                            value: "",
                            label: "Имя",
                            items: [""],
                            isCyrilic: true,
                            visible: false,
                            fieldType: "autocomplete",
                            "item-text": "value",

                            placeholder: "Укажите имя",
                            rules: [VR.required],
                            key: "name",
                            isMobile: true,
                            input: (value) => {
                                this.connector
                                    .getDadataFio(value, "name")
                                    .then((data) => {
                                        let suggestions = data.suggestions;
                                        this.anketa.fio.fields[2].items = [
                                            {value},
                                            ...suggestions,
                                        ];
                                    });
                                this.anketa.fio.name = value;
                            },
                        },
                        {
                            value: "",
                            label: "Отчество",
                            items: [""],
                            isCyrilic: true,
                            placeholder: "Укажите отчество",
                            isMobile: true,
                            visible: false,
                            fieldType: "autocomplete",
                            "item-text": "value",

                            key: "patronymic",
                            input: (value) => {
                                this.connector
                                    .getDadataFio(value, "patronymic")
                                    .then((data) => {
                                        let suggestions = data.suggestions;
                                        // .filter((item) => !!item.data.patronymic)
                                        // .map((item) => ({
                                        //   value: [
                                        //     item.data.surname,
                                        //     item.data.name,
                                        //     item.data.patronymic,
                                        //   ]
                                        //       .join(" ")
                                        //       .trim(),
                                        // }));

                                        this.anketa.fio.fields[3].items = [
                                            {value},
                                            ...suggestions,
                                        ];
                                    });
                                this.anketa.fio.middleName = value;
                            },
                        },
                    ],
                },
                accountNumberBank: {
                    label: "Номер счета",
                    value: "",
                    type: "tel",
                    autocomplete: "off",
                    mask: "4081 #### #### #### ####",
                    placeholder: "20 цифр, начинается с 4081...",
                    isBank: true,
                    errorMessages: "",
                    cleanValue() {
                        return cleanRules.withoutSpace(this.value);
                    },
                    grid: {
                        cols: 12,
                        lg: 12,
                        sm: 12,
                    },
                    rules: [
                        VR.required,
                        VR.accountNumber,
                        VR.correctAccountNumber
                    ],
                    validateBankAccount: (val) => {
                        this.anketa.accountNumberBank.errorMessages = "";
                        var result = false;
                        val = val.replace(/\s+/g, "");
                        let bik = this.anketa.accountBank.bik.toString();

                        var bikKs = bik.slice(bik.length - 3, bik.length) + val;
                        var checksum = 0;
                        var coefficients = [7, 1, 3, 7, 1, 3, 7, 1, 3, 7, 1, 3, 7, 1, 3, 7, 1, 3, 7, 1, 3, 7, 1];
                        for (var i in coefficients) {
                            checksum += coefficients[i] * (bikKs[i] % 10);
                        }
                        if (checksum % 10 === 0) {
                            result = true;
                        } else {
                            this.anketa.accountNumberBank.errorMessages = "Неверный номер счета";
                        }
                        return result;
                    },
                    input: (value) => {
                        if (this.anketa.accountNumberBank.value.length >= 24 && this.anketa.accountBank.bik) {
                            this.anketa.accountNumberBank.validateBankAccount(value);
                        }
                    }
                },
                accountCard: {
                    value: "",
                    label: "Номер карты",
                    mask: "#### #### #### ####",
                    type: "tel",
                    autocomplete: "off",
                    placeholder: "16 цифр",
                    grid: {
                        lg: 12,
                        xs: 12
                    },
                    cleanValue() {
                        return cleanRules.withoutSpace(this.value);
                    }, rules: [VR.required, VR.accountCard, VR.validCard],
                },
                income: {
                    value: "",
                    label: "Ваш доход (₽ в месяц)",
                    placeholder: "70 000",
                    type: "tel",
                    cleanValue() {
                        return cleanRules.withoutSpace(this.value);
                    },
                    localeString: true,
                    grid: {
                        cols: 6,
                        xl: 6,
                        lg: 6,
                        sm: 6,
                        xs: 12
                    },
                    rules: [VR.required],
                },
                incomeMain: {
                    value: "",
                    label: "Ваш доход (₽ в месяц)",
                    placeholder: "70 000",
                    type: "tel",
                    grid: {
                        lg: 12,
                        xs: 12
                    },
                    cleanValue() {
                        return cleanRules.withoutSpace(this.value);
                    },
                    localeString: true,
                    // inputmode: 'numeric',
                    // isMoney: true
                    // rules: [VR.incomeNumbers]
                },
                accountBank: {
                    label: "Банк",
                    placeholder: state.data().state.type() !== 'travelata' ? 'Введите БИК или наименование банка' : 'Введите БИК',
                    "item-text": "value",
                    fieldType: "autocomplete",
                    items: [],
                    isCyrilic: true,
                    value: "",
                    bik: "",
                    mask: "",
                    grid: {
                        lg: 12,
                        xs: 12
                    },
                    valueAdditionalInfoCorrespondentAccount: "",
                    valueAdditionalInfoBankName: "",
                    errorMessages: '',
                    rules: [VR.required],
                    input: (val) => {
                        return this.connector.getDadataBankId(val).then((data) => {
                            if (data.suggestions.length != 0) {
                                this.anketa.accountBank.items =
                                    data.suggestions;

                                if (val === data.suggestions[0].value) {
                                    this.anketa.accountBank.errorMessages = '';
                                    this.anketa.accountBank.additionalInfoBankName = false;

                                    this.anketa.accountBank.bik =
                                        data.suggestions[0].data.bic;
                                    this.anketa.accountBank.valueAdditionalInfoBankName =
                                        data.suggestions[0].value;
                                    this.anketa.accountBank.valueAdditionalInfoCorrespondentAccount =
                                        data.suggestions[0].data.correspondent_account;

                                    if (this.anketa.accountNumberBank.value.length >= 24) {
                                        this.anketa.accountNumberBank.validateBankAccount(this.anketa.accountNumberBank.value);
                                    }

                                } else {
                                    this.anketa.accountBank.errorMessages = 'выберите из списка';
                                }
                            } else {
                                this.anketa.accountBank.additionalInfoBankName = true;
                                this.anketa.accountBank.valueAdditionalInfoBankName =
                                    "Банк не найден. Проверьте правильность БИК.";
                            }
                        });
                    },
                },
                codeSMS: {
                    mask: "#####",
                    placeholder: state.data().state.type() !== 'bankiros' ? "Код из смс" : 'Пароль из СМС',
                    type: "tel",
                    rules: [VR.required, VR.code],
                    success: false,
                    hideValue: state.data().state.type() !== 'travelata' && state.data().state.type() !== 'bankiros',
                    errorMessage: "",
                    grid: {
                        lg: 12,
                        xs: 12
                    },
                    input: (val) => {
                        if (val.length === 5) {
                            this.$root.metrika88149184.reachGoal(
                                "entering_code"
                            );

                            this.anketa.codeSMS.success = false;
                            this.anketa.codeSMS.errorMessage = '';

                            this.connector
                                .isSingApplication(val)
                                .then((data) => {
                                    console.log('codeSmsm isSingApplication data', data)
                                    if (data.success) {
                                        console.log('ссылка дата shortlink', data)
                                        this.anketa.codeSMS.success = true;
                                        this.anketa.shortLink.value = data.shortlink;
                                    } else {
                                        this.anketa.codeSMS.success = false;

                                        if (data.message === 'Code Is Not Valid' || data.message.reason === 'Code Is Not Valid') {
                                            this.anketa.codeSMS.errorMessage = 'Введен неправильный код'
                                        } else if (data.message === 'Code Does Not Exist Or Has Expired' || data.message.reason === 'Code Does Not Exist Or Has Expired') {
                                            this.anketa.codeSMS.errorMessage = 'Слишком много отправленных кодов. Попробуйте запросить новый код позже или обратитесь в техническую поддержку сервиса'
                                        } else {
                                            this.anketa.codeSMS.errorMessage = data.message;
                                        }
                                    }
                                })
                                .catch((error) => {
                                    this.anketa.codeSMS.success = false;
                                    this.anketa.codeSMS.errorMessage = error?.response?.data?.detail || error;
                                });
                        }
                    },
                    value: "",
                },
                status: "",
                shortLink: {
                    // value: "https://paylate.ru/admin",
                    value: "",
                },
                receiveOnCard: {
                    value: false,
                },
                photoPassport: {
                    value: null,
                    requiredInput: true,
                    label: "Фото паспорта - главная страница",
                    fieldType: "file",
                    placeholder: "PNG, JPG, PDF",
                    grid: {
                        cols: 12,
                        xl: 6,
                        lg: 6,
                        md: 6,
                        sm: 12,
                        xs: 12
                    },
                    condition: "passportRequired",
                    rules: [VR.required],
                    file: {},
                    ...fileFieldLoadingAttributes,
                    input: (file) => {
                        // this.anketa.photoPassport.send(
                        // this.$root.connector.saveDocuments,
                        this.anketa.photoPassport.success = true;
                        this.anketa.photoPassport.file = {
                            value: "photoPassport",
                            img: `data:${file.type};base64,${file.data}`,
                        };

                        // );
                    },
                },
                photoPassportRegistration: {
                    value: null,
                    requiredInput: true,
                    label: "Фото паспорта - прописка",
                    fieldType: "file",
                    placeholder: "PNG, JPG, PDF",
                    rules: [VR.required],
                    condition: "passportRequired",
                    grid: {
                        cols: 12,
                        xl: 6,
                        lg: 6,
                        md: 6,
                        sm: 12,
                        xs: 12
                    },
                    file: {},
                    ...fileFieldLoadingAttributes,
                    input: (file) => {
                        this.anketa.photoPassportRegistration.success = true;
                        this.anketa.photoPassportRegistration.file = {
                            value: "photoPassportRegistration",
                            img: `data:${file.type};base64,${file.data}`,
                        };
                    },
                    img: "pass2",
                },
                photoSecondDocumentFront: {
                    value: null,
                    requiredInput: true,
                    label: "Фронтальная сторона",
                    file: {},
                    grid: {
                        cols: 12,
                        xl: 6,
                        lg: 6,
                        md: 6,
                        sm: 12,
                        xs: 12
                    },
                    condition: "filesRequired",

                    rules: [VR.required],
                    placeholder: "PNG, JPG, PDF",
                    fieldType: "file",
                    ...fileFieldLoadingAttributes,
                    input: (file) => {
                        this.anketa.photoSecondDocumentFront.success = true;
                        this.anketa.photoSecondDocumentFront.file = {
                            value: "photoSecondDocumentFront",
                            img: `data:${file.type};base64,${file.data}`,
                        };
                    },
                    img: "pass3",
                },
                photoSecondDocumentBack: {
                    value: null,
                    requiredInput: true,
                    label: "Обратная сторона",
                    file: {},
                    grid: {
                        cols: 12,
                        xl: 6,
                        lg: 6,
                        md: 6,
                        sm: 12,
                        xs: 12
                    },
                    condition: "filesRequired",
                    rules: [VR.required],
                    placeholder: "PNG, JPG, PDF",
                    fieldType: "file",
                    ...fileFieldLoadingAttributes,
                    input: (file) => {
                        this.anketa.photoSecondDocumentBack.success = true;
                        this.anketa.photoSecondDocumentBack.file = {
                            value: "photoSecondDocumentBack",
                            img: `data:${file.type};base64,${file.data}`,
                        };
                    },
                    img: "pass3",
                },
                photoSPassportWithUser: {
                    value: null,
                    condition: "photoWithPassportRequired",
                    requiredInput: true,
                    label: "Селфи с паспортом",
                    file: {},
                    rules: [VR.required],
                    placeholder: "PNG, JPG, PDF",

                    fieldType: "file",
                    ...fileFieldLoadingAttributes,
                    input: (file) => {
                        this.anketa.photoSPassportWithUser.success = true;
                        this.anketa.photoSPassportWithUser.file = {
                            value: "photoSPassportWithUser",
                            img: `data:${file.type};base64,${file.data}`,
                        };
                    },
                    img: "passuser",
                    grid: {
                        cols: 12,
                        xl: 6,
                        lg: 6,
                        md: 6,
                        sm: 12,
                        xs: 12
                    }
                },
                firstDateSlider: [],
                initialPayment: {
                    value: 3000,
                    comment: "Не более 50% от суммы покупки",
                    fieldType: "slider",
                    grid: {
                        cols: 12,
                        sm: 10,
                    },
                    type: "tel",
                    min: 0,
                    max: 50,
                    step: 10,
                    initialMetricCondition: false,
                    // append: "%",
                    // isMoney: true,
                    input: () => {
                        if (!this.anketa.initialPayment.initialMetricCondition) {
                            // if(this.state.isNordwind()){
                            //     this.$root.metrika96799237.reachGoal("slider_sum");
                            // }else {
                            //     this.$root.metrika88149184.reachGoal("slider_sum");
                            // }
                            // state.data().state.addYandexMetricGoals('slider_sum')
                            this.$root[`metrika${this.$root.state.addYandexMetricGoals()}`].reachGoal("slider_sum");
                            this.anketa.initialPayment.initialMetricCondition = true
                        }
                        this.anketa.conditions.calculate(
                            this.anketa.initialPayment.value,
                            this.anketa.term.value
                        );
                    },
                },
                term: {
                    label: "Срок, год",
                    comment: "От 4 до 12 месяцев",
                    fieldType: "slider",
                    type: "tel",
                    valueTerm: 12,
                    percent: 12,
                    value: 6,
                    // append: "от 2 до 5",
                    grid: {
                        cols: 12,
                        sm: 10,
                    },
                    // isMoney: true, // Костыль, надо isNumber
                    input: () => {
                        this.anketa.conditions.calculate(
                            this.anketa.initialPayment.value,
                            this.anketa.term.value
                        );
                    },
                },
                conditions: {
                    monthlyPayment: 0,
                    finSecurity: false,
                    rateId: 0,
                    shopId: 0,
                    initialPayment: 0, // ?
                    calculate: (initialPayment, term) => {
                        if (this.anketa.firstDateSlider.length != 0) {
                            this.anketa.term.valueTerm = this.anketa.firstDateSlider[term - 1].period;
                            this.anketa.term.percent = this.anketa.firstDateSlider[term - 1].percent;
                            let initialPropertyPayment = this.anketa.firstDateSlider[term - 1];
                            this.anketa.conditions.monthlyPayment = parseInt((((initialPayment / 100) * initialPropertyPayment.percent * initialPropertyPayment.period) + initialPayment) / initialPropertyPayment.period);
                            this.anketa.conditions.rateId = initialPropertyPayment.rateId;
                            this.anketa.conditions.shopId = initialPropertyPayment.shopId;
                            this.anketa.conditions.initialPayment = initialPayment;
                        }
                    },
                },
                creditInformation: {},
                actionInfo: false,
                windowWidth: window.innerWidth,
                insuranceSelected: [],
                birthdate: {
                    value: "",
                    label: "Дата рождения",
                    fieldType: "date",
                    placeholder: "дд.мм.гггг",
                    grid: {
                        xl: 6,
                        lg: 6,
                        sm: 6,
                        xs: 12,
                        cols: 6,
                    },
                    dateValue() {
                        return cleanRules.dateValue(this.value);
                    },
                    input: () => {
                        if (this.anketa.issueDate.value) {
                            this.anketa.issueDate.input(
                                this.anketa.issueDate.value
                            );
                        }
                    },
                    rules: [
                        VR.required,
                        VR.dateCount,
                        VR.date,
                        VR.birthdate.limitations,
                    ],
                },
                passport: {
                    label: "Серия и номер",
                    mask: "## ## - ######",
                    type: "tel",
                    cleanValue() {
                        return cleanRules.passport(this.value);
                    },
                    grid: {
                        cols: 12,
                        xl: 6,
                        lg: 6,
                        md: 12,
                        sm: 12,
                        xs: 12
                    },
                    rules: [VR.required, VR.passport],
                },
                issueDate: {
                    label: "Дата выдачи",
                    fieldType: "date",
                    placeholder: "дд.мм.гггг",
                    grid: {
                        cols: 12,
                        xl: 3,
                        lg: 3,
                        sm: 6,
                        xs: 12
                    },
                    value: "",
                    errorMessages: "",
                    dateValue() {
                        return cleanRules.dateValue(this.value);
                    },
                    min: null,
                    setRange: () => {
                        const birthdate = this.anketa.birthdate.value;
                        const age = getYears(birthdate);
                        const min = 20;
                        const max = 45;

                        if (age >= min && age <= max) {
                            this.anketa.issueDate.min =
                                moment(birthdate, "YYYY-MM-DD")
                                    .add(min, 'years')
                                    .add(-1, 'days')
                                    .format("YYYY-MM-DD");
                        } else if (age >= max) {
                            this.anketa.issueDate.min =
                                moment(birthdate, "YYYY-MM-DD")
                                    .add(max, 'years')
                                    .add(-1, 'days')
                                    .format("YYYY-MM-DD");
                        }

                        return moment(this.anketa.issueDate.min, "YYYY-MM-DD").year();
                    },
                    input: (value) => {
                        if (moment(value, "YYYY-MM-DD").year() - this.anketa.issueDate.setRange() >= 0) {
                            this.anketa.issueDate.errorMessages = "";
                        } else if (this.anketa.birthdate.value) {
                            this.anketa.issueDate.errorMessages =
                                "Введите корректную дату выдачи пасспорта";
                        }
                    },
                    rules: [VR.required, VR.dateCount, VR.date],
                },
                divisionCode: {
                    value: "",
                    label: "Код подразделения",
                    mask: "### - ###",
                    type: "tel",
                    grid: {
                        cols: 12,
                        xl: 3,
                        lg: 3,
                        sm: 6,
                        xs: 12
                    },
                    cleanValue() {
                        return cleanRules.withoutSpace(this.value);
                    },
                    rules: [VR.required, VR.divisionCode],
                    input: () => {
                        this.anketa.issuedBy.fill();
                    },
                },
                issuedBy: {
                    value: "",
                    label: "Кем выдан",
                    placeholder: "Наименование органа, выдавшего паспорт",
                    isCyrilicIssued: true,
                    rules: [VR.required],
                    grid: {
                        cols: 12,
                        xl: 12,
                        lg: 12,
                        md: 12,
                        sm: 12,
                        xs: 12
                    },
                    fill: () => {
                        if (
                            VR.divisionCode(this.anketa.divisionCode.value) ===
                            true &&
                            VR.dateCount(this.anketa.issueDate.value) === true
                        ) {
                            this.connector
                                .checkPassportIssuer()
                                .then(
                                    (issuer) =>
                                        (this.anketa.issuedBy.value =
                                            "" + issuer.Issuer[0])
                                );
                        }
                    },
                },
                residentialAddress: {
                    value: "",
                    label: "Адрес проживания",
                    fieldType: "autocomplete",
                    "item-text": "value",
                    items: [],
                    grid: {
                        cols: 12,
                        xl: 12,
                        lg: 12,
                        md: 12,
                        sm: 12,
                        xs: 12
                    },
                    checkboxValue: true,
                    checkboxText: "Совпадает с адресом регистрации",
                    placeholder: "Город, улица, дом, квартира",
                    isCyrilicPlace: true,
                    selected: false,
                    rules: [
                        VR.required,
                        () => this.anketa.residentialAddress.selected || "Выберите из списка"
                    ],
                    errorMessages: "",
                    addressInfo: {},
                    input: (val) => {
                        console.log(val);
                        this.connector.getDadataAddress(val).then((data) => {
                            console.log('residentialAddress dadata', data)
                            if (data.suggestions[0]) {
                                const addressData = data?.suggestions[0]?.data;

                                this.anketa.residentialAddress.addressInfo.block = addressData.block || "";
                                this.anketa.residentialAddress.addressInfo.city = addressData.city;
                                this.anketa.residentialAddress.addressInfo.flat = addressData.flat || "";
                                this.anketa.residentialAddress.addressInfo.house = addressData.house;
                                this.anketa.residentialAddress.addressInfo.index = addressData.postal_code;
                                this.anketa.residentialAddress.addressInfo.region = addressData.region_type_full
                                    ? addressData.region + " " + addressData.region_type_full : addressData.region;
                                this.anketa.residentialAddress.addressInfo.street = addressData.street;
                                this.anketa.residentialAddress.items = data.suggestions;
                                this.anketa.residentialAddress.addressInfo.settlement_with_type = addressData.settlement_with_type;
                                this.anketa.residentialAddress.addressInfo.region_with_type = addressData.region_with_type;
                            }
                        });
                    },
                    change: (val) => {
                        if (val) {
                            this.anketa.residentialAddress.grid.lg = 12;
                        } else {
                            this.anketa.residentialAddress.grid.lg = 12;
                        }

                        this.anketa.registrationAddress.visible = !val;
                        this.anketa.residentialAddress.checkboxValue = val;
                    },
                },
                registrationAddress: {
                    value: "",
                    label: "Адрес регистрации",
                    fieldType: "autocomplete",
                    placeholder: "Город, улица, дом, квартира",
                    "item-text": "value",
                    items: [],
                    grid: {
                        cols: 12,
                        xl: 12,
                        lg: 12,
                        md: 12,
                        sm: 12,
                        xs: 12
                    },
                    selected: false,
                    isCyrilicPlace: true,
                    rules: [
                        VR.required,
                        () => this.anketa.registrationAddress.selected || "Выберите из списка"
                    ],
                    addressInfo: {},
                    visible: false,
                    selectRequired: true,
                    input: (val) => {
                        this.connector.getDadataAddress(val).then((data) => {
                            console.log('registrationAddress dadata', data)
                            if (data.suggestions[0]) {
                                const addressData = data?.suggestions[0]?.data;

                                this.anketa.registrationAddress.addressInfo.block = addressData.block || "";
                                this.anketa.registrationAddress.addressInfo.city = addressData.city;
                                this.anketa.registrationAddress.addressInfo.flat = addressData.flat || "";
                                this.anketa.registrationAddress.addressInfo.house = addressData.house;
                                this.anketa.registrationAddress.addressInfo.index = addressData.postal_code;
                                this.anketa.registrationAddress.addressInfo.region =
                                    addressData.region_type_full
                                        ? addressData.region + " " + addressData.region_type_full : addressData.region;
                                this.anketa.registrationAddress.addressInfo.street = addressData.street;
                                this.anketa.registrationAddress.addressInfo.settlement_with_type = addressData.settlement_with_type;

                            }

                            this.anketa.registrationAddress.items = data.suggestions;
                        });
                    },
                },
                registerPhone: {
                    label: "Мобильный телефон",
                    value: "",
                    mask: "+7 ### ### ## ##",
                    isPhone: true,
                    cleanValue() {
                        return cleanRules.phone(this.value);
                    },
                    valueFirstDigitIs9() {
                        return cleanRules.phoneFirstDigitIs9(this.value);
                    },
                    grid: {
                        cols: 12,
                    },
                    rules: [VR.required, VR.phoneFirstDigitIs9, VR.phone],
                    disabled: false,
                    type: "tel",
                },
                registerCode: {
                    mask: "#####",
                    // placeholder: "_____",
                    placeholder: state.data().state.type() === 'travelata' || state.data().state.type() === 'bankiros' ? 'Введите код' : '_____',
                    type: "tel",
                    rules: [VR.required, VR.code],
                    success: false,
                    grid: {
                        lg: 12,
                        xs: 12
                    },
                    // input: (val) => {
                    //     if (val.length === 5) {
                    //         this.anketa.registerCode.success = true;
                    //         console.log('success');
                    //         // this.connector
                    //         //     .sopdVerify(
                    //         //         val,
                    //         //         this.anketa.phone.valueFirstDigitIs9()
                    //         //     )
                    //         //     .then((data) => {
                    //         //         if (data.success) {
                    //         //             this.anketa.registerCode.success = true;
                    //         //         } else {
                    //         //             this.anketa.registerCode.success = false;
                    //         //         }
                    //         //     })
                    //         //     .catch(() => {
                    //         //         this.anketa.registerCode.success = false;
                    //         //     });
                    //     }
                    // },
                    input: () => {
                        console.log('anketa state', state.data().state.type())
                    },
                    value: "",
                },
                employmentType: {
                    label: "Вид занятости",
                    value: {value: 0, text: "Выберите нужное"},
                    list: [
                        {value: 0, text: "Выберите нужное"},
                        {value: 1, text: "Работаю в организации"},
                        {value: 2, text: "Собственный бизнес"},
                        {value: 3, text: "Фрилансер"},
                        {value: 4, text: "Студент"},
                        {value: 5, text: "Декрет"},
                        {value: 6, text: "Пенсионер"},
                        {value: 7, text: "Не работаю"},
                    ],
                    fieldType: "select",
                    grid: {
                        cols: 12,
                        xl: 6,
                        lg: 6,
                        md: 6,
                        sm: 12,
                        xs: 12
                    },
                    rules: [VR.requiredSelect],
                    condition: "employmentRequired",
                },
                organizationName: {
                    value: "",
                    label: "Полное название организации",
                    placeholder: "Укажите название организации",
                    grid: {
                        cols: 12,
                        xl: 6,
                        lg: 6,
                        md: 6,
                        sm: 12,
                        xs: 12
                    },
                    rules: [VR.required],
                    condition: "employmentRequired",
                },
                position: {
                    value: "",
                    label: "Должность",
                    placeholder: "Укажите вашу должность",
                    grid: {
                        cols: 12,
                        xl: 6,
                        lg: 6,
                        md: 6,
                        sm: 12,
                        xs: 12
                    },
                    rules: [VR.required],
                    condition: "employmentRequired",
                },
                organizationAddress: {
                    value: "",
                    label: "Адрес организации",
                    placeholder: "Юридический адрес организации",
                    fieldType: "autocomplete",
                    items: [],
                    "item-text": "value",
                    grid: {
                        cols: 12,
                        md: 6,
                    },
                    rules: [VR.required],
                    input: (value) => {
                        this.connector.getDadataAddress(value).then((data) => {
                            let suggestions = data.suggestions;
                            this.anketa.organizationAddress.items = [
                                {value},
                                ...suggestions,
                            ];
                        });
                    },
                    condition: "employmentRequired",
                },
                employmentPeriod: {
                    label: "Ваш рабочий стаж",
                    value: {value: 0, text: "Укажите стаж"},
                    list: [
                        {value: 0, text: "Укажите стаж"},
                        {value: 1, text: "Менее 3 месяцев"},
                        {value: 2, text: "3 - 6 месяцев"},
                        {value: 3, text: "6 - 12 месяцев"},
                        {value: 4, text: "1 - 3 года"},
                        {value: 5, text: "Более 3 лет"},
                        {value: 6, text: "Не указано"},
                    ],
                    fieldType: "select",
                    grid: {
                        cols: 12,
                        xl: 6,
                        lg: 6,
                        md: 6,
                        sm: 12,
                        xs: 12
                    },
                    rules: [VR.requiredSelect],
                    condition: "employmentRequired",
                },
                workPhone: {
                    label: "Рабочий телефон",
                    value: "",
                    mask: "+7 ### ### ## ##",
                    isPhone: true,
                    cleanValue() {
                        return cleanRules.workPhone(this.value);
                    },
                    grid: {
                        cols: 12,
                        xl: 6,
                        lg: 6,
                        md: 6,
                        sm: 12,
                        xs: 12
                    },
                    rules: [
                        // VR.required,
                        // VR.phoneFirstDigitIs9,
                        VR.workPhone
                    ],
                    type: "tel",
                    condition: "employmentRequired",

                    // input: (value) => {
                    //     console.log('value workPhone', cleanRules.workPhone(value))
                    // }
                },
                additionalPhone: {
                    label: "Мобильный телефон",
                    value: "",
                    mask: "+7 ### ### ## ##",
                    isPhone: true,
                    cleanValue() {
                        return cleanRules.phone(this.value);
                    },
                    grid: {
                        cols: 12,
                        xl: 12,
                        lg: 12,
                        md: 12,
                        sm: 12,
                        xs: 12
                    },
                    rules: [VR.required, VR.phoneFirstDigitIs9, VR.phone],
                    type: "tel",
                    condition: "additionalPhoneNumberRequired",
                },


                promocode: {
                    value: "",
                    promocodeValue: '',
                    mask: "",
                    onFocus: false,
                    showPromocodeLogo: true,
                    fieldType: 'promocode',
                    placeholder: '',
                    grid: {
                        cols: 12,
                        xl: 12,
                        lg: 12,
                        md: 12,
                        sm: 12,
                        xs: 12
                    },
                    isDisabled: false,
                    isPromocode: true,
                    promocodeOptions: {
                        // begin: true,
                        sendRequest: false,
                        ok: false,
                        error: false
                    },
                    promocodeInfo: 'Промокод',
                    input: (value) => {

                        if (value.length >= 6) {
                            this.anketa.promocode.promocodeOptions.sendRequest = true

                        } else if (value.length < 6) {
                            this.anketa.promocode.promocodeOptions.sendRequest = false
                        } else if (this.anketa.promocode.value === '') {
                            this.anketa.promocode.promocodeOptions.sendRequest = false
                        }
                    },
                },

                // Нужные данные для правильного отображения полей в localhost в NeedMoreData.vue, при изменении v-if="router.needMoreData" на true. Без ее поля будут схлопнуты!
                // additionalData: {
                //     employmentRequired: true,
                //     additionalPhoneNumberRequired: true,
                //     photoWithPassportRequired: true,
                //     passportRequired: true,
                //     filesRequired: true,
                // }
            },
        };
    },
};
