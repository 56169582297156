<template>
    <div>
        <div class="modal-screen-desc">
            <p :class="$root.appType('travelata') ? 'travelata_desc-tooltip' : 'desc-tooltip'">
                {{ label }}
            </p>
            <div v-if="label === 'Номер счета'" class="helper tooltip-position">
                <span
                    v-if="!$root.appType('travelata') && !$root.appType('bankiros')"
                    @click="isTooltipFind = !isTooltipFind"
                    class="helper-text"
                    >Где найти?
                </span>
                <span
                    v-if="$root.appType('travelata') || $root.appType('bankiros')"
                    @click="isTooltipFind = !isTooltipFind"
                    class="travelata_helper-text"
                    >Где его найти?
                </span>
                <Tooltip v-show="isTooltipFind"></Tooltip>
            </div>
        </div>
        <div class="modal-screen-input">
            <v-text-field
                v-mask="mask"
                v-model="fieldValue"
                autocomplete="false"
                :placeholder="placeholder || virtualPlaceholder"
                :type="!isHidden ? type : 'password'"
                solo
                flat
                :outlined="outlined"
                :errorMessages="errorMessages"
                :hide-details="false"
                background-color="white"
                validate-on-blur
                :rules="rules"
                :disabled="isDisabled"
                @blur="onBlur"
                @focus="onFocus"
                :key="updateKey"
                :autofocus="autofocus"
                :class="$root.appType('travelata') ? 'travelata_input' : ''"
            >
                <template #append>
                    <v-icon
                        v-if="hideValue !== undefined && !$root.appType('travelata') && !$root.appType('bankiros')"
                        @click="isHidden = !isHidden"
                        class="hide-icon"
                        >{{ isHidden ? "mdi-eye-off" : "mdi-eye" }}
                    </v-icon>

                    <span class="textSecondary--text">{{ appendText }}</span>
                </template>
            </v-text-field>
        </div>

        <label class="is-size-5">{{ comment }}</label>
        <slot name="inputAdditionalInfo"></slot>
    </div>
</template>

<script>
import Tooltip from "@/components/controls/Tooltip";
import { replaceToCyrilic } from "../helpers/ReplaceToCyrylic";

export default {
    props: {
        value: [Number, String, Boolean],
        label: String,
        comment: String,
        append: String,
        mask: String,
        maskValue: String,
        type: String,
        rules: Array,
        placeholder: String,
        errorMessages: String,
        outlined: Boolean,
        hideValue: {
            type: Boolean,
            default: () => undefined,
        },
        disabled: Boolean,
        replaceEngToRu: {
            type: Boolean,
            default: true,
        },
        // Ограничения ввода:
        isBank: Boolean,
        isPhone: Boolean,
        isMoney: Boolean,
        localeString: Boolean,
        isCyrilic: Boolean,
        isCyrilicIssued: Boolean,
        isCyrilicPlace: Boolean,
        isEngEmail: Boolean,
        anketaName: String
    },

    components: {
        Tooltip,
    },

    mounted() {
        if (this.label === "Номер счета") {
            document.addEventListener("click", (e) => {
                if (
                    !e.target.closest(".tooltip") &&
                    !e.target.closest(".tooltip-position")
                ) {
                    this.isTooltipFind = false;
                }
            });
        }
    },

    computed: {
        fieldValue: {
            get() {
                if (this.isMoney && this.value) {
                    return this.value
                        .toString()
                        .replace(/(\d)(?=(\d{3})+([^\d]|$))/g, "$1 ");
                }
                else {
                    return this.value;
                }
            },
            set(val) {
                let oldval = val;

                if (val != this.value) {

                    val = this.applyInputRestrictions(val);

                    if(this.anketaName === 'incomeMain' || this.anketaName === 'income'){
                        val = val.replace(/\D/g, '')
                        val = val.split('').join('').replace(/\B(?=(\d{3})+(?!\d))/g, " ").split('').join('').trim();
                        this.$emit("input", val);
                        this.updateKey++;
                        this.autofocus = true;

                    }
                    else {
                        this.$emit("input", val);
                        if (val != oldval) {
                            // перерисовка позволяет видеть в поле ввод с ограничениями
                            this.updateKey++;
                            // autofocus позволяет мобильной клавиатуре оставаться на месте
                            // при перерисовке поля по updateKey
                            this.autofocus = true;
                        }

                        else {
                            this.autofocus = false;
                        }

                    }


                }
            },

            // set(val) {
            //     let oldval = val;

            //     if (val != this.value) {
            //         val = this.applyInputRestrictions(val);

            //         this.$emit("input", val);

            //         if (val != oldval) {
            //             // перерисовка позволяет видеть в поле ввод с ограничениями
            //             this.updateKey++;
            //             // autofocus позволяет мобильной клавиатуре оставаться на месте
            //             // при перерисовке поля по updateKey
            //             this.autofocus = true;
            //         }

            //         else {
            //             this.autofocus = false;
            //         }
            //     }
            // },

        },
        appendText() {
            return this.append;
        },
        virtualPlaceholder() {
            if (this.mask) {
                return this.mask.replace(/#/g, "0");
            } else {
                return null;
            }
        },

        // isHidden() {
        //   return this.hideValue;
        // },

        isDisabled() {
            return this.disabled;
        },

        // hiddingText(){
        //     // !isHidden ? type : 'password'
        //
        // }
    },
    data() {
        return {
            updateKey: 0,
            autofocus: false,
            isTooltipFind: false,
            prevValue: "",
            isHidden: this.hideValue,
        };
    },

    methods: {
        applyInputRestrictions(val) {
            let oldval = val;
            let key;

            if (this.isMoney) {
                val = val.replace(/\D/g, "");
            }
            if (this.isEngEmail) {
                val = val.replace(/[^a-zA-Z\-0-9.@]/g, "");
            }
            if (this.isCyrilic) {
                val = val.replace(/[^а-яА-ЯёЁ -]/g, "");
            }
            if (this.isCyrilicIssued) {
                val = val.replace(/[^0-9А-яЁё/\\(№\-.,")\s]/g, "");
            }
            if (this.isCyrilicPlace) {
                val = val.replace(/[^0-9А-яЁё("\-.)\s]/g, "");
            }
            if (this.localeString) {
                // val = Number(val.replace(/\s+/g, "")).toLocaleString();
                // val = val.replace(/\D/g, "");
                val = val.replace(/\D/g, "");
            }
            if (this.isBank) {
                if (val.length === 1 && this.value === "") {
                    val = val.replace(/^(.{0,4})/, "4081" + val);
                }
            }
            if (this.isPhone) {
                if (val.length === 1 && this.value === "") {
                    val = val.replace(/^(.{0,2})/, "+7" + val);
                }
            }
            if (this.replaceEngToRu) {
                key = oldval.replace(val, "");
                if (key.length == 1) {
                    val += replaceToCyrilic(key);
                }
            }

            return val;
        },
        onBlur(e) {
            this.$emit("blur", e);
        },
        onFocus() {
            if (this.value === "" && (this.maskValue ?? this.mask)) {
                const inputValue = this.maskValue || this.mask;
                const focusValue = inputValue.replace(/[\s- #]/g, "");
                console.log('focusValue', focusValue)
                if (focusValue !== "") {
                    this.$emit("input", focusValue);
                    this.updateKey++;
                    this.autofocus = true;
                }
            }
        },
    },
};
</script>

<style lang="scss" scoped>
@import '@/assets/scss/_travelata-variables.scss';

.travelata {
    &_helper-text {
        @include tr-text(12px, 400 ,16px);
        font-family: 'OpenSansR';
        color: #059BCE;
    }
}
</style>
