<template>
        <div
            class="bankiros-questions">
            <div class="container">
                <div class="bankiros-questions__body">
                    <h2 class="is-size-8 text-center">
                        Частые вопросы
                    </h2>
                    <Accordion
                        :accordions="otherAccordions"
                        class="mb-6 mt-4"
                        icon="mdi-chevron-down"
                        light
                        flat
                    />
                </div>
            </div>
    </div>
</template>

<script>
import Accordion from '@/components/global/Accordion.vue'

export default {
    components: {Accordion, },
    name: "BankirosQuestions",
    data: () => ({
        otherAccordions: [
            {
                id: 1,
                action: false,
                itemPanel: 'Как можно вернуть проценты по кредиту*?',
                bodyPanel: 'Совершите ежемесячные платежи, кроме последнего в соответствии с графиком и в срок, установленный условиями акции.  \n' +
                    'После списания предпоследнего платежа в течение 14 дней направьте письмо на адрес: info@paylate.info, с номером лицевого счета/аккаунта и словами «Акция «Заём 0%» в теме письма. \n' +
                    'В течение 7-ми дней сумма начисленных и уплаченных процентов за весь период пользования заёмных средств поступит на баланс вашего лицевого счета.\n' +
                    'Зачисленная сумма может быть использована для будущих покупок через сервис «Paylate – Доверительная оплата» или перечислена на ваш счёт в банке РФ. \n' +
                    `Полные условия акции <a target="_blank" href="./docs/loan0.pdf">тут</a>.`
            },
            {
                id: 2,
                action: true,
                itemPanel: 'Как быстро я получу деньги?',
                bodyPanel: 'Мы отправим деньги после подписания договора:\n' +
                    '- переводы через СБП (по номеру телефона) осуществляются в течение нескольких минут;\n' +
                    '- переводы на банковскую карту могут занимать до нескольких часов;\n' +
                    '- переводы по банковским реквизитам могут занять до 24 часов, но как правило операции проходят гораздо быстрее.'
            },
            {
                id: 3,
                action: true,
                itemPanel: 'Какой размер ставки по кредиту*?',
                bodyPanel: 'ПСК для нецелевого займа может находиться в диапазоне от 0% до 292%. Процентная ставка за использование заемных средств будет рассчитываться индивидуально, исходя из кредитных показателей клиента, и составлять от 1,9% в месяц от суммы первоначального займа. После подачи заявки система определит точную процентную ставку.'
            },
            {
                id: 4,
                action: true,
                itemPanel: 'На какие цели я могу взять деньги?',
                bodyPanel: 'Средства предоставляются на любые цели, используйте их на ваше усмотрение.'
            },
            {
                id: 5,
                action: true,
                itemPanel: 'Способы погашения кредита*',
                bodyPanel: `- банковской картой: VISA, MasterCard, Мир;
                    - электронный кошелек: терминалы Элекснет/online кабинет;
                    - наличными без комиссии: в банкоматах и в отделениях банков.
                  `
            },
            {
                id: 6,
                action: true,
                itemPanel: 'Можно ли погасить кредит* досрочно?',
                bodyPanel: 'Да, вы можете досрочно погасить всю сумму на любом этапе. При полном погашении кредита<sup class="other">*</sup> в льготный период проценты не оплачиваются или оплачиваются по льготной процентной ставке, в остальных случаях переплата взимается за фактический срок пользования кредита<sup class="other">*</sup> .'
            },
        ],
    }),
}
</script>

<style lang="scss">
.bankiros {
    .bankiros-questions {
        padding: 60px 0;

        background: var(--v-grey-base);

        .v-expansion-panel {
            box-shadow: none;
            background: transparent !important;
            border-bottom: 1px solid #E8ECEF;

            &:first-child {
                border-top: 1px solid #E8ECEF;
            }

            .v-expansion-panel-header {
                box-shadow: none;
                padding: 20px 20px 20px 45px;
                font-size: 18px;
                font-weight: 700;
                position: relative;

                &:before {
                    content: url("../../assets/img/bankiros/question-icon.svg");
                    width: 23px;
                    height: 23px;
                    position: absolute;
                    left: 0;
                    opacity: 1;
                    background-color: transparent;
                    top: 50%;
                    transform: translateY(-50%);
                }
            }

            .v-expansion-panel-content__wrap {
                padding: 0 20px 20px 45px;
                font-size: 14px;
            }
        }

        @media (max-width: 600px) {
            padding: 30px 0;
        }
    }
}
</style>
