<template>
    <v-app :class="$root.state.type()">
        <v-main>
            <NordWindMainPage v-if="$root.appType('nordwind')"
            />
            <MainPage v-if="$root.appType('cash')"
            />
            <VtbMainPage
                v-if="$root.appType('vtb')"
            />
            <BankirosMainPage
                v-if="$root.appType('bankiros')"
                class="bankiros-page"
            />
            <TravelataMainPage
                v-if="$root.appType('travelata')"
                class="travelata"
            />

            <FormHTML/>
        </v-main>
    </v-app>
</template>

<script>
import MainPage from './components/MainPage';
import NordWindMainPage from './components/NordWindMainPage.vue';
import VtbMainPage from './components/VtbMainPage.vue';
import BankirosMainPage from "@/components/bankiros/MainPage.vue";
import FormHTML from "@/components/modal/FormHTML.vue";
import TravelataMainPage from "@/components/TravelataMainPage.vue";

export default {
    name: 'App',
    components: {
        FormHTML,
        BankirosMainPage,
        MainPage,
        NordWindMainPage,
        TravelataMainPage,
        VtbMainPage
    },
    computed: {
        isNordwind() {
            return this.$root.state.isNordwind()
        },
    },

  mounted() {
    this.$nextTick(() => {
      window.addEventListener('resize', () => {
        this.$root.anketa.windowWidth = window.innerWidth
      });
    })

        setTimeout(() => {
            this.$root.mindbox("Пробыл на сайте более 30 сек");
        }, 30000)
    },
};
</script>

<style lang="scss" scoped>

</style>
