<template>
    <div class="auth">
        <!-- <div
            @click="backToBirthDay"
            class="modal-icon modal-back auth_back-btn"
        >
          <v-btn icon>
            <img src="../../assets/img/arrow.svg" alt="logotype" />
          </v-btn>
        </div> -->
        <div class="main-option-for-app">
            <h1
                :class="addClass('forms_title')"
                class="mb-6"
                >{{!showRegisterCodeField ? "До получения денег осталось заполнить два поля!" : "Подтверждение"}}
            </h1>
            <p
                :class="$root.appType('travelata') ? 'travelata_auth_subtitle' : 'auth_subtitle'"
                class="mb-6 text-left"
                >{{!showRegisterCodeField ? "" : `Введите код, отправленный на номер +${anketa.phone.valueWithMask()}`}}
            </p>
        </div>

        <v-form @submit.prevent ref="form">
            <FieldsCard
                v-if="!showRegisterCodeField"
                :anketaNames="['birthdate']"
                :altCols="{
                    cols: 12,
                    xl: 12,
                    lg: 12,
                    sm: 12,
                    xs: 12,
                }"
                class="mb-5"
            />
            <FieldsCard
                v-if="!showRegisterCodeField"
                :anketaNames="['incomeMain']"
            />
            <FieldsCard
                v-if="showRegisterCodeField"
                :anketaNames="['registerCode']"
            />

            <div v-if="isSmsForm && showRegisterCodeField" class="helper">
                <span class="helper-text" @click="changeTemplate(true)"></span>
                <span
                    @click="callSopd"
                    class="helper-text"
                    >Отправить код повторно
                </span>
            </div>
            <InfoTooltip
                v-show="showErrorTooltip && $root.appType('travelata')"
                @close-tooltip="closeTooltip"
            />
<!--            <v-btn-->
<!--                @click="auth"-->
<!--                :loading="loading"-->
<!--                :disabled="loading"-->
<!--                :class="!$root.appType('vtb') ? 'rectangle-button' : 'vtb-button'"-->
<!--                class="mt-6"-->

<!--                >{{ !showRegisterCodeField ? "Далее" : "Отправить заявку" }}-->
<!--            </v-btn>-->
                <MainButton
                    @click="auth"
                    :loading="loading"
                    :disabled="loading"
                    class="mt-6"
                    >{{ !showRegisterCodeField ? "Далее" : "Отправить заявку" }}
                </MainButton>
        </v-form>
        <div
            v-if="errorAuthTextApi.status && errorAuthTextApi.text !== ''"
            class="auth_error"
        >
            <p class="error-text">{{ errorAuthTextApi.text }}</p>
        </div>
        <InfoTooltip
            v-show="showErrorTooltip && $root.appType('cash') || $root.appType('nordwind')"
            @close-tooltip="closeTooltip"
        />
        <BankirosInfoTooltip
            class="mt-8"
            v-show="showErrorTooltip && $root.appType('bankiros')"
            @close-tooltip="closeTooltip"
        />
        <p
            v-show="showErrorTooltip && $root.appType('vtb')"
            class="auth_vtb_info_error"
            >Вы указали неверные данные. Пожалуйста, проверьте еще раз.
        </p>
    </div>
</template>

<script>
import FieldsCard from "@/components/FieldsCard";
import InfoTooltip from "../controls/InfoTooltip.vue";
import MainButton from "@/components/global/MainButton.vue";
import BankirosInfoTooltip from "@/components/bankiros/controls/InfoTooltip.vue";

export default {
    name: "Auth",
    components: {
        BankirosInfoTooltip,
        FieldsCard,
        InfoTooltip,
        MainButton
    },
    data: () => ({
        loading: false,
        clientExists: false,
        errorAuthTextApi: {
            status: false,
            text: "",
        },
        showRegisterCodeField: false,
        sopdIsSended: false,
        isSmsForm: false,
        showFieldBeforeExist: false,
        showErrorTooltip: false,
        // sopdSendWithoutError: true,
        exceptionsTypes: ['cash', 'nordwind']
    }),
    computed: {
        connector() {
            return this.$root.connector;
        },
        anketa() {
            return this.$root.anketa;
        },
        isNordwind(){
            return this.$root.state.isNordwind()
        }
    },
    methods: {
        takeParentsInformation(flag) {
            const data = {
                success: flag,
                prev: "account",
                next: "statusWait",
            };

            this.$emit("changeTemplate", data, false);
        },
        changeTemplate(flag) {
            const data = {
                success: flag,
                prev: "auth",
                next: "recoveryPass",
                // status:
            };

            this.$emit("changeTemplate", data);
        },

        callSopd() {
            this.connector.sopdSendWithBirthday(
                this.anketa.phone.valueFirstDigitIs9(),
                this.anketa.birthdate.value
            );
        },

        auth() {
            if (!this.sopdIsSended) {
                this.connector
                    .sopdSendWithBirthday(
                        this.anketa.phone.valueFirstDigitIs9(),
                        this.anketa.birthdate.value
                    )
                    .then((response) => {
                        console.log(response);

                        if (response.success === false) {
                            this.errorAuthTextApi.status = true;
                            if (response.message === "Указанные данные принадлежат другому клиенту.") {
                                this.errorAuthTextApi.text = "";
                                this.showErrorTooltip = true;
                                // if(this.isNordwind){
                                //     this.$root.metrika96799237.reachGoal("auth_error");
                                // }
                                // else if(this.$root.appType('cash')) {
                                //     this.$root.metrika88149184.reachGoal("auth_error");
                                // }
                                // else if(this.$root.appType('vtb')){
                                //     this.$root.metrika97329470.reachGoal("auth_error");
                                // }
                                // else if(this.$root.appType('travelata')){
                                //     this.$root.metrika98070141.reachGoal("auth_error");
                                // }
                                this.$root.state.addYandexMetricGoals('auth_error')
                                this.$root.mindbox("Неудачно авторизовался");
                            } else {
                                this.errorAuthTextApi.text = response.message;
                            }
                            // this.errorAuthTextApi.text = response.message;
                        } else {
                            this.errorAuthTextApi.status = false;
                            this.showRegisterCodeField = true;
                            this.sopdIsSended = true;
                            this.showErrorTooltip = false;
                        }
                    });

                this.isSmsForm = true;
                this.loading = false;
            } else {
                this.connector
                    .sopdVerify(
                        this.anketa.registerCode.value,
                        this.anketa.phone.valueFirstDigitIs9()
                    )
                    .then((response) => {
                        this.errorAuthTextApi.status = false;
                        if (response.success) {
                            localStorage.setItem("token", response.auth.token);
                            localStorage.setItem("token_dt", response.auth.token_dt);

                            const createData = {
                                rate_id: this.anketa.conditions.rateId,
                                period: this.anketa.term.valueTerm,
                                percent: this.anketa.term.percent,
                                amount: this.anketa.initialPayment.value,
                                //account: this.anketa.receiveOnCard.value && this.anketa.conditions.initialPayment <= 40000 ? "" : this.anketa.accountNumberBank.cleanValue(),
                                account: "40811111111111111111",
                                // bik: this.anketa.receiveOnCard.value && this.anketa.conditions.initialPayment <= 40000 ? "" : this.anketa.accountBank.bik,
                                bik: "111111111",
                                // card_number: this.anketa.receiveOnCard.value && this.anketa.conditions.initialPayment <= 40000 ? this.anketa.accountCard.cleanValue() : "",
                                card_number: "1111111111111111",
                                // bank_name: this.anketa.accountBank.valueAdditionalInfoBankName,
                                bank_name:
                                    "Банк не найден. Проверьте правильность БИК.",
                                // correspondent_account: this.anketa.accountBank.valueAdditionalInfoCorrespondentAccount,
                                correspondent_account: "1111111111111111",
                                merge: false,
                                token: localStorage.getItem("token"),
                                token_dt: localStorage.getItem("token_dt"),
                                phone: this.anketa.phone.valueFirstDigitIs9(),
                                income_amount: this.anketa.incomeMain.cleanValue(),
                            };

                            this.connector
                                .isAuthAndCreateApplication(createData)
                                .then((response) => {
                                    // if(response.success) {
                                    //     this.anketa.applicationId.value = response.application_id;
                                    // }
                                    this.anketa.status = response.status;
                                    // if(this.isNordwind){
                                    //     this.$root.metrika96799237.reachGoal("auth_success");
                                    // }
                                    // else if(this.$root.appType('cash')) {
                                    //     this.$root.metrika88149184.reachGoal("auth_success");
                                    // }
                                    // else if(this.$root.appType('vtb')){
                                    //     this.$root.metrika97329470.reachGoal("auth_success");
                                    // }
                                    // else if(this.$root.appType('travelata')){
                                    //     this.$root.metrika98070141.reachGoal("auth_success");
                                    // }
                                    this.$root[`metrika${this.$root.state.addYandexMetricGoals()}`].reachGoal("auth_success");
                                    this.$root.mindbox("Авторизовался");

                                    if (response.type === 2) {
                                        const stepData = {
                                            success: true,
                                            prev: "auth",
                                            next: "checkApplication",
                                            // next: "statusWait",
                                        };

                                        this.anketa.creditInformation = response.info;
                                        this.anketa.creditInformation.months = response.info.period;
                                        this.anketa.creditInformation.monthlyPayment =
                                            parseInt(
                                                (((+response.info.amount * response.info.percent) / 100) *
                                                    response.info.period + +response.info.amount) / response.info.period
                                            );

                                        if (response.status === "needMoreData") {
                                            this.anketa.additionalData = response.info.required;
                                        }

                                        if (response.status === "approved" || response.status === "merged") {
                                            for (let k = 0;k <this.anketa.creditInformation.insuranceData.length;k++) {
                                                if (this.anketa.creditInformation.insuranceData[k].id === this.anketa.creditInformation.insuranceSelected) {
                                                    this.anketa.creditInformation.insuranceData[k].insuranceSelected = true;
                                                } else {
                                                    this.anketa.creditInformation.insuranceData[k].insuranceSelected = false;
                                                }
                                            }
                                        }
                                        this.$emit("changeTemplate", stepData);
                                    } else {
                                        this.takeParentsInformation(true);
                                    }
                                })
                                .catch((error) => {
                                    this.errorAuthTextApi.status = true;
                                    this.errorAuthTextApi.text = error;
                                });
                        } else {
                            console.log("auth error response", response);
                            if (response.message === "Указанные данные принадлежат другому клиенту.") {
                                this.errorAuthTextApi.text = "Указанные данные принадлежат другому клиенту?";
                            } else {
                                this.errorAuthTextApi.text = response.message;
                            }
                            // console.log('все тут!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!', this.isNordwind)
                            // if(this.isNordwind){
                            //     console.log('auth_error ym nordwind')
                            //     this.$root.metrika96799237.reachGoal("auth_error");
                            // }else {
                            //     console.log('auth_error ym cash')
                            //     this.$root.metrika88149184.reachGoal("auth_error");
                            // }
                            // this.$root.mindbox("Неудачно авторизовался");
                            this.errorAuthTextApi.status = true;
                        }
                    })
                    .catch((error) => {
                        // if(this.isNordwind){
                        //     this.$root.metrika96799237.reachGoal("auth_error");
                        // }
                        // else if(this.$root.appType('cash')) {
                        //     this.$root.metrika88149184.reachGoal("auth_error");
                        // }
                        // else if(this.$root.appType('vtb')){
                        //     this.$root.metrika97329470.reachGoal("auth_error");
                        // }
                        // else if(this.$root.appType('travelata')){
                        //     this.$root.metrika98070141.reachGoal("auth_error");
                        // }
                        this.$root[`metrika${this.$root.state.addYandexMetricGoals()}`].reachGoal("auth_error");
                        this.$root.mindbox("Неудачно авторизовался");
                        this.errorAuthTextApi.status = true;
                        if (error.message === "Network Error") {
                            this.errorAuthTextApi.text = "Произошла невообразимая ситуация!";
                        } else {
                            this.errorAuthTextApi.text = "Вы ввели неправильный пароль";
                        }
                    })
                    .finally(() => (this.loading = false));
            }
        },

        checkExists() {
            const stepData = {
                success: true,
                prev: "auth",
                next: "registration",
            };

            this.connector
                .clientExists(this.anketa.phone.cleanValue())
                .then((response) => {
                    if (response.success && response.clientExists) {
                        // this.isSmsForm = true
                        return (this.clientExists = true);
                    } else if (response.success && !response.clientExists) {
                        // this.showFieldBeforeExist = true
                        if (this.showFieldBeforeExist) {
                            if (!this.sopdIsSended) {
                                this.connector
                                    .sopdSendWithBirthday(
                                        this.anketa.phone.valueFirstDigitIs9(),
                                        this.anketa.birthdate.value
                                    )
                                    .then((response) => {
                                        if (response.success === false) {
                                            this.errorAuthTextApi.status = true;
                                            this.errorAuthTextApi.text = response.message;
                                        }
                                    });

                                this.sopdIsSended = true;
                                this.showRegisterCodeField = true;
                                this.loading = false;
                            } else {
                                this.connector
                                    .sopdVerify(
                                        this.anketa.registerCode.value,
                                        this.anketa.phone.valueFirstDigitIs9()
                                    )
                                    .then((response) => {
                                        this.errorAuthTextApi.status = false;
                                        if (response.success) {
                                            localStorage.setItem(
                                                "token",
                                                response.auth.token
                                            );
                                            localStorage.setItem(
                                                "token_dt",
                                                response.auth.token_dt
                                            );
                                            this.$emit(
                                                "changeTemplate",
                                                stepData
                                            );
                                        }
                                    });
                            }
                        } else {
                            this.$emit("changeTemplate", stepData);
                            this.showFieldBeforeExist = true;
                        }
                    }
                    this.anketa.phone.disabled = true;
                    this.anketa.registration = true;
                })
                .catch((error) => {
                    this.errorAuthTextApi.status = true;
                    if (error.message === "Network Error") {
                        this.errorAuthTextApi.text =
                            "Произошла невообразимая ситуация!";
                    }
                    // else {
                    //     this.$emit("changeTemplate", stepData);
                    // }
                })
                .finally(() => (this.loading = false));
        },
        validate() {
            if (this.$refs.form.validate()) {
                this.validFormBIK = true;
            }
        },

        closeTooltip() {
            this.showErrorTooltip = false;
        },

        addClass(cssClass){
            let searchingClass = null
            // console.log('addClass', this.exceptionsTypes.find(this.$root.state.type()))
            if(this.exceptionsTypes.find(el => el === this.$root.state.type())) {
                searchingClass = `${cssClass}`
            }else {
                searchingClass = `${this.$root.state.type()}_${cssClass}`
            }
            return searchingClass
        },

    },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/_vtb-variables.scss";
@import "@/assets/scss/_travelata-variables.scss";

.auth {
    &_title {
        font-size: 16px !important;
    }
    // position: relative;
    &_back-btn {
        position: absolute !important;
        top: 16px;
        left: 24px !important;
    }

    &_error {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 10px;
        margin-bottom: 20px;

        & > svg {
            cursor: pointer;
        }
    }

    &_vtb_info_error {
        @include vtb-form-label;
        color: #1976D2;
        margin-top: 20px;
    }

    &_subtitle {
        color: #656565;
    }
}

.travelata {
    &_auth_subtitle {
        @include tr-text();
        color: #707070;
        font-family: 'OpenSansR';
    }

}
</style>
