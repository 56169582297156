<template>
    <div id="cashback" class="bankiros-cashback">
        <div class="container">
            <h2 class="is-size-8 text-center">Как получить кешбэк</h2>

            <v-row class="bankiros-cashback__body mt-sm-14 mt-4">
                <v-col class="bankiros-cashback__item text-center" v-for="(item, index) in items" v-bind:key="index + item.title">
                    <div class="bankiros-cashback__item_icon is-size-6">
                      <span>{{index + 1}}</span>
                    </div>

                    <div class="bankiros-cashback__item_text">
                        <h3 class="is-size-7 font-weight-bold mt-14">{{ item.title }}</h3>
                        <p class="is-size-4 mb-0 mt-3">{{item.description}}</p>
                    </div>
                </v-col>
            </v-row>

            <div class="bankiros-cashback__buttons justify-center d-flex align-center mt-8">
                <v-btn @click="$vuetify.goTo('#widget', 500)" class="rectangle-button m-0">
                    Получить деньги
                </v-btn>

                <a class="d-flex align-center dark-grey--text is-size-3 text-decoration-none" target="_blank" :href="urlParams('ac') ? './docs/index.pdf' : './docs/loan0.pdf'">
                    Полные условия акции
                    <img class="ml-1" src="@/assets/img/bankiros/link.svg" alt="link">
                </a>

            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "BankirosCashback",

    data: () => ({
        items: [
            {
                title: 'Оформите кредит*',
                description: 'Срок 3, 6, 10 или 12 мес.'
            },
            {
                title: 'Погашайте в срок',
                description: 'Ежемесячно погашайте не позднее чем за 10 дней до даты платежа'
            },
            {
                title: 'Напишите нам',
                description: 'Отправьте заявку на возврат средств'
            },
        ]
    }),
    methods: {
        urlParams(param){
            const urlLocation = location.search.split('&')
            return urlLocation.includes(param)
        },
    },
    // computed: {
    //     docLink() {
    //         return this.
    //     }
    // }
}
</script>

<style lang="scss" scoped>
.bankiros-cashback {
    background-color: #fff;
    padding: 60px 0;
    position: relative;
    z-index: 2;

    &__buttons {
        position: relative;

        a {
            position: absolute;
            right: 0;
        }
    }

    &__item {
        display: flex;
        flex-direction: column;
        align-items: center;
        position: relative;

        p {
            max-width: 400px;
        }

        &:nth-child(2) {
            &:before {
                content: "";
                width: 60%;
                height: 2px;
                position: absolute;
                left: 0%;
                top: 35px;

                transform: translateX(-50%);

                background-image: linear-gradient(to right, #7FC03C 60%, rgba(255,255,255,0) 0%);
                background-position: bottom;
                background-size: 15px 2px;
                background-repeat: repeat-x;
            }
            &:after {
                content: "";
                width: 60%;
                height: 2px;
                position: absolute;
                right: 0%;
                top: 35px;

                transform: translateX(50%);

                background-image: linear-gradient(to right, #7FC03C 60%, rgba(255,255,255,0) 0%);
                background-position: bottom;
                background-size: 15px 2px;
                background-repeat: repeat-x;
            }
        }

        &_icon {
            width: 50px;
            height: 50px;
            background-color: #7FC03C;
            border-radius: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
            text-align: center;
            position: relative;

            span {
                position: relative;
                z-index: 1;
                color: #ffffff;
            }

            &:before {
                content: "";
                position: absolute;
                width: 200%;
                height: 200%;
                border-radius: 50%;
                background-color: rgba(127, 192, 60, 0.30);
            }
        }
    }

    .rectangle-button {
        max-width: 160px;
    }

    @media (max-width: 768px) {
        &__item {
            &:nth-child(2) {
                &:before {
                    width: 50%;
                }
                &:after {
                    width: 50%;
                }
            }
        }

        .is-size-4 {
            font-size: 16px;
        }
    }

    @media (max-width: 650px) {
        padding: 30px 0;

        &__body {
            flex-direction: column;

            gap: 20px;
        }

        &__buttons {
            flex-direction: column;

            button {
                max-width: 350px !important;
            }

            a {
                position: static;
                margin-top: 20px;
            }
        }

        &__item {
            flex-direction: row;

            .is-size-7 {
                margin-top: 0 !important;
                text-align: left;
            }

            p {
                text-align: left;
            }

            &_text {
                margin-left: 30px;
            }

            &_icon {
                width: 40px;
                height: 40px;
                font-size: 16px;

                flex:  0 0 40px;

                &:before {
                    width: 150%;
                    height: 150%;
                }
            }

            &:nth-child(2) {
                &:before {
                    width: 2px;
                    height: 50%;
                    top: 0px;
                    left: 30px;
                    transform: translateY(-50%);

                    background-image: linear-gradient(#7FC03C 50%, rgba(255,255,255,0) 0%);
                    background-position: right;
                    background-size: 15px 12px;
                    background-repeat: repeat-y;
                }

                &:after {
                    width: 2px;
                    height: 50%;
                    bottom: 0px;
                    left: 30px;
                    transform: translateY(100%);

                    background-image: linear-gradient(#7FC03C 50%, rgba(255,255,255,0) 0%);
                    background-position: right;
                    background-size: 15px 12px;
                    background-repeat: repeat-y;
                }
            }
        }
    }
}
</style>
