<template>
  <div>
    <Accordion
      :accordion="true"
      :flat="true"
      class="hide-info mt-5"
      >
      <template v-slot:header>
          <span>
            Как сделать фото правильно
            <img v-if="$root.appType('bankiros')" src="@/assets/img/bankiros/help-circle.svg"/>
            <img v-if="$root.appType('cash') || $root.appType('nordwind')" src="@/assets/img/help-circle.svg"/>
            <img v-if="$root.appType('vtb')" src="@/assets/img/vtb/vtb_help-circle.svg"/>
            <img v-if="$root.appType('travelata')" src="@/assets/img/travelata/travelata_question.svg"/>
          </span>
      </template>
      <p v-if="$root.appType('vtb')" class="vtb_docs-info_accordion_text">Убедитесь, что ничего не закрыто и нет бликов - все буквы и цифры должны быть четко видны</p>
      <div
        class="card_list"
        v-for="card in ($vuetify.breakpoint.lg ? cards : cardsMobile)"
        :key="card.title"
      >
        <h4 class="card_list__title mb-3">{{ card.title }}</h4>
        <Card :card="card"/>
      </div>
    </Accordion>
  </div>
</template>

<script>
import Card from "@/components/global/Card";
import Accordion from "@/components/global/Accordion";

export default {
  components: {Accordion, Card},
  data: () => ({
    cards: [
      {
        title: 'Фото паспорта / селфи с паспортом',
        body: [
          {
            image: require('@/assets/img/passport-1.png'),
            text: '<b>Главный разворот:</b> две страницы, на одной ваше фото',
          },
          {
            image: require('@/assets/img/passport-2.png'),
            text: '<b>Разворот:</b> (две страницы с регистрацией)',
          },
          {
            image: require('@/assets/img/passport-3.png'),
            text: 'Правила для селфи с паспортом',
          },
          {
            list: [
              'раскройте паспорт на главном развороте',
              'снимите очки, если носите',
              'лицо должно быть открыто',
              'сделайте фото без бликов с паспортом в руках'
            ],
          }
        ],
      },
      {
        title: 'Фото водительских прав и СНИЛС',
        body: [
          {
            image: require('@/assets/img/passport-4.png'),
            text: '<b>Права</b> лицевая сторона документа',
          },
          {
            image: require('@/assets/img/passport-5.png'),
            text: '<b>Права:</b> обратная сторона документа',
          },
          {
            image: require('@/assets/img/passport-6.png'),
            text: '<b>СНИЛС:</b> лицевая и обратная стороны документа',
          },
        ],
      },
    ],
    cardsMobile: [
      {
        title: 'Фото паспорта',
        body: [
          {
            image: require('@/assets/img/passport-1.png'),
            text: '<b>Главный разворот:</b> две страницы, на одной ваше фото',
          },
          {
            image: require('@/assets/img/passport-2.png'),
            text: '<b>Разворот:</b> (две страницы с регистрацией)',
          },
        ],
      },
      {
        title: 'Селфи с паспортом',
        body: [
          {
            image: require('@/assets/img/passport-3.png'),
          },
          {
            text: 'Правила для селфи с паспортом',
            list: [
              'раскройте паспорт на главном развороте',
              'снимите очки, если носите',
              'лицо должно быть открыто',
              'сделайте фото без бликов с паспортом в руках'
            ],
          }
        ],
      },
      {
        title: 'Фото водительских прав',
        body: [
          {
            image: require('@/assets/img/passport-4.png'),
            text: 'лицевая сторона документа',
          },
          {
            image: require('@/assets/img/passport-5.png'),
            text: 'обратная сторона документа',
          },
        ],
      },
      {
        title: 'Фото СНИЛС',
        body: [
          {
            image: require('@/assets/img/passport-6.png'),
            text: 'Лицевая и обратная стороны документа',
          },
        ],
      },
    ]
  }),
}
</script>

<style lang="scss" scoped>
  @import "@/assets/scss/_vtb-variables.scss";

.vtb_docs-info_accordion_text {
  @include vtb-form-subtitle;
  font-size: 14px;
  line-height: 19px;
  color: #656565;
  text-align: left;
}
</style>
