<template>
    <div class="bankiros-header">
        <div class="container">
            <div class="flex-sm-row flex-column d-flex justify-space-between align-center">
                <div class="bankiros-header__images d-flex align-center">
                    <a class="mr-7" target="_blank" href="https://bankiros.ru/">
                        <img src="@/assets/img/bankiros/bankiros-logo.svg" alt="bankiros" />
                    </a>

                    <a target="_blank" href="https://paylate.ru/">
                        <img src="@/assets/img/bankiros/paylate-logo.svg" alt="paylate" />
                    </a>
                </div>

                <a class="is-size-6 mt-sm-0 mt-3 text-decoration-none font-weight-bold black--text" href="tel:+78007700300">8-800-770-03-00</a>
            </div>

        </div>
    </div>
</template>

<script>
export default {
    name: "BankirosHeader"
}
</script>

<style lang="scss" scoped>
.bankiros-header {
    background-color: #fff;
    padding: 30px 0;

    @media (max-width: 768px) {
        .bankiros-header__images {
            img, a {
                height: 30px;
                width: auto;
            }
        }

        a {
            font-size: 20px;
        }
    }

    @media (max-width: 600px) {
        .bankiros-header__images {
            img, a {
                height: 25px;
                width: auto;
            }
        }

        a {
            font-size: 18px;
        }
    }
}
</style>
