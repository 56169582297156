<template>
  <div v-if="visible">
    <h4
        :class="$root.appType('travelata') ? 'travelata_block-title' : 'block-title'"
        class="text-left mb-3"
        >{{ title }}
    </h4>

    <v-row>
      <Field
        v-for="name in fields"
        :key="name"
        :anketaName="name"
        :altCols="name.grid"
      />
    </v-row>
  </div>
</template>

<script>
import Field from "@/components/Field";

export default {
    components: {Field,},
    props: {
        fields: Array,
        title: String,
        conditions: Array
    },

    computed: {
        additionalData() {
          return this.$root.anketa.additionalData;
        }
    },

    data: () => ({
        visible: true
    }),

    created() {
        let count = 0;

        this.conditions.forEach(condition => {
          if (this.additionalData[condition]) {
            count++
          }
        })

        if (count === 0) {
          this.visible = false;
        }
    },
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/_travelata-variables.scss';

.travelata_block-title {
    @include tr-text(20px, 600);
    color: #333;
    font-family: 'OpenSansSB';
    margin-bottom: 20px !important;
}
</style>
