<template>
    <div class="info-tooltip">
        <img src="@/assets/img/bankiros/warning.svg" alt="warning">

        <div class="">
            <p>Указанный номер телефона уже зарегистрирован в сервисе под другим аккаунтом. Пожалуйста, укажите другой номер телефона.</p>

            <p>Нужна помощь? Обратитесь по номеру <a class="blue--text text-decoration-none" href="tel:+78007700300">8 (800) 770-0300</a> </p>
        </div>
    </div>
</template>

<script>
export default {
    name: "BankirosInfoTooltip",
}
</script>

<style lang="scss" scoped>
.info-tooltip {
    padding: 24px;
    border-radius: 12px;
    border: 1px solid #EFEFEF;
    text-align: left;
    display: flex;
    align-items: flex-start;
    gap: 10px;

    background: #FCFCFC;

    img {
        margin-top: 4px;
    }
}
</style>
