<template>
  <div>
    <div class="modal-screen-desc">
      <p v-if="!$root.appType('travelata')" class="desc-tooltip">{{ label }}</p>
      <p v-if="$root.appType('travelata')" class="travelata_desc-tooltip">{{ label }}</p>
    </div>

    <div class="modal-screen-input select-input">
      <v-select
        v-model="fieldValue"
        :items="items"
        label=""
        persistent-hint
        return-object
        single-line
        solo
        flat
        :hide-details="false"
        :rules="rules"
        :class="$root.appType('travelata') ? 'travelata_input' : ''"
        >

        <template #append>
          <v-icon class="mt-1">mdi-chevron-down</v-icon>
        </template>

      </v-select>
    </div>
  </div>
</template>

<script>
export default {
    props: {
        value: [Number, String, Object],
        label: String,
        append: String,
        list: Array,
        rules: Array,
    },
    computed: {
        fieldValue: {
          get() {
            return this.value;
          },
          set(val) {
            this.$emit('input', val);
          }
        },
        items() {
          return this.list;
        },
        appendText() {
          return this.append;
        },
    },
}
</script>

<style scoped>
/* .v-text-field__details { display: none; } */
</style>
