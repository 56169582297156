<template>
    <div class="bankiros_cashback">
        <div
            v-if='anketa.actionInfo && params === "ac"'
            class='bankiros_cashback_container'>
            <div class='bankiros_cashback_title'>
                <h4 class="text-left">
                    Ваш кешбэк: {{ anketa.term.valueTerm === 12 ? cashback : "0" }}
                    <b>&#8381;</b>
                </h4>
            </div>
            <p
                v-if="anketa.term.valueTerm === 12"
                class="bankiros_cashback_text">
                Чтобы получить кешбэк, выберите срок от 3х месяцев
            </p>
            <p class="bankiros_cashback_details" @click="$vuetify.goTo('#cashback', 500)">
                Подробнее
            </p>
        </div>
        <div
            v-if='anketa.actionInfo && params === "acnew"'
            class='bankiros_cashback_container'>
            <div class='bankiros_cashback_title'>
                <h4>
                    Ваш кешбэк: {{ anketa.term.valueTerm === 1 ? "0" : cashback }}
                    <b class='bankiros-ruble-icon'>&#8381;</b>
                </h4>
            </div>
            <p v-if="anketa.term.valueTerm === 1" class="bankiros_cashback_text">
                Чтобы получить кешбэк, выберите срок от 3х месяцев
            </p>

            <p class="bankiros_cashback_details" @click="$vuetify.goTo('#cashback', 500)">
                Подробнее
            </p>
        </div>
    </div>
</template>

<script>
import {moneyFormatWithSpace} from "@/components/helpers/MoneyFormat";

export default {
    name: 'BankirosSliderCashback',
    props: {
        params: String
    },

    computed: {
        anketa() {
            return this.$root.anketa;
        },
        cashback: {
            get() {
                return moneyFormatWithSpace((this.anketa.initialPayment.value / 100) * this.anketa.term.percent * (this.anketa.term.valueTerm - 1));
            },
        },
        // cashback() {
        //     // if (this.anketa.term.valueTerm > 1 && this.params === "acnew") {
        //     // }
        //
        //     return this.cashbackValue
        // },
    }
}
</script>

<style lang="scss" scoped>

.bankiros_cashback {
    &_container {
        margin-top: 24px;
        background-color: rgba(241, 244, 247, 0.8);
        border-radius: 12px;
        padding: 24px;

        h4 {
            font-style: normal;
            font-weight: 600;
            font-size: 20px;
            line-height: 24px;
        }

        p {
            margin: 0;
        }
    }

    &_details {
        cursor: pointer;
        display: inline-block;
        color: #388EC3;
        margin-top: 20px !important;
    }

    &_text {
        margin-top: 12px !important;
        font-size: 16px;
    }
}

</style>
