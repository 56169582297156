<template>
    <div>
        <div class="main-option-for-app text-left">
            <h1 class="forms_title">Заявка отклонена</h1>

            <p class="mt-3 blue--text">К сожалению, вам отказано в предоставлении сервиса</p>

            <p class="status-img mt-8">
                <img src="@/assets/img/bankiros/sad.png">
            </p>

            <p>
                <span class="grey2--text">
                    Но вы можете получить деньги у наших партнёров.
                </span>
                Уровень одобрения по вашей заявке будет составлять
                 <span class="blue--text">96%</span>
            </p>

            <MainButton>
                Одобренные предложения
            </MainButton>
        </div>
    </div>
</template>

<script>

import MainButton from "@/components/global/MainButton.vue";

export default {
    name: "BankirosDeclined",
    components: {MainButton},
    computed: {},

    mounted() {
        this.$root[`metrika${this.$root.state.addYandexMetricGoals()}`].reachGoal("reject");
        this.$root.mindbox("Получил отказ по заявке");
    }
}
</script>

<style lang="scss" scoped>

</style>
