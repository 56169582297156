<template>
    <!--footer-->
    <footer class="footer">
        <div class="container">
            <p class="is-size-3">
                * Здесь и далее под «Кредит» понимается заём. Заём предоставляется микрокредитной компанией ООО "МКК "Кредит Лайн", регистрационный номер записи в государственном реестре микрофинансовых организаций 2120150001955 в соответствии со свидетельством ФСФР России №0002195 от 14.08.2012. ** Информация в соответствии с
                <a target="_blank" href="https://xn----8sbkdqibmuwz.xn--p1ai/docs/Information_in_accordance_with_353-FZ.pdf">п. 4 ст. 5 ФЗ N 353-ФЗ</a> .
                <br>
                <br>
                <a target="_blank" href="https://paylate.ru/o-servise/">Информация о сервисе</a>.
                <a target="_blank" href="https://xn----8sbkdqibmuwz.xn--p1ai/">Официальный сайт займодателя</a>
            </p>
        </div>
    </footer>
</template>

<script>
export default {
    name: "BankirosFooter",
}
</script>

<style lang="scss" scoped>

.footer {
    padding: 20px 0 60px 0;

    p {
        margin: 0;
    }

    a {
        text-decoration: none !important;
    }

    @media (max-width: 600px) {
        p {
            font-size: 14px;
        }
    }
}

</style>
