<template>
    <div>
        <div >
            <div class='main-option-for-app'>
                <h1 class="text-left">{{title}}</h1>
                <p v-html="text" class="mb-5 mt-4 text-left status-waiting_subtitle">

                </p>

                <p class="blue--text m-0 text-left" v-if="timeExpired">Пожалуйста, не закрывайте окно.</p>
            </div>

            <div class='preloader' >
                <img src='@/assets/img/bankiros/preloader-bankiros.gif'/>
            </div>
            <p
                class='error-text text-left'
                v-show='errorAuthTextApi.status'>
                {{ errorAuthTextApi.text }}
            </p>
        </div>
    </div>
</template>
<script>
export default {
    name: "BankirosStatusWaiting",
    props: {
        router: [Array, Object],
        errorAuthTextApi: Object,

        merge: Boolean,
    },

    data: () => ({
        title: 'Проверяем заявку',
        text: 'Время ожидания несколько минут. <span class="blue--text">Пожалуйста, не закрывайте окно.</span>',
        timeExpired: false
    }),

    mounted() {
        setTimeout(() => {
            this.title = 'Заявка в обработке - скоро все будет готово!';
            this.text = 'В случае необходимости с вами свяжется оператор для уточнения данных. <br/> Благодарим за терпение!'

            this.timeExpired = true;
        }, 120000)
    }
};
</script>

<style lang='scss' scoped>
p {
    color: #848485;
}

h1 {
    color: #333E50;
}
</style>
