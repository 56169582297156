<template>
    <div>
        <transition-group name="show-fade" mode="out-in">
            <Phone
                v-if="router.phone"
                key="phone"
                @changeTemplate="goNextStep"
            />
            <Account
                v-if="router.account"
                key="account"
                @changeTemplate="goNextStep"
            />
            <Auth
                v-if="router.auth"
                key="auth"
                @changeTemplate="goNextStep"
            />
            <Registration
                v-if="router.registration"
                key="registration"
                @changeTemplate="goNextStep"
            />
            <RecoveryPass
                v-if="router.recoveryPass"
                key="recoveryPass"
                @changeTemplate="goNextStep"
            />
            <CheckApplication
                v-if="router.checkApplication"
                key="checkApplication"
                @changeTemplate="goNextStep"
            />
            <Back
                v-if="router.back"
                key="back"
                :prevRoute="currentRoute"
                @changeTemplate="goNextStep"
            />
            <StatusWaiting
                v-if="router.statusWait"
                :merge="merge"
                :router="router"
                key="statusWait"
                :waitingData="waitingData"
                @changeTemplate="changeStatusAndTemplate"
            />
            <NeedMoreData
                v-if="router.needMoreData"
                key="needMoreData"
                @changeTemplate="changeStatusAndTemplate"
            />
            <AccessApplication
                v-if="router.accessApplication"
                key="accessApplication"
                @changeTemplate="changeStatusAndTemplate"
            />
            <SmsPage
                v-if="router.smsPage"
                key="smsPage"
            />
            <Declined
                v-if="router.declined"
                key="declined"
            />
        </transition-group>
    </div>
</template>

<script>
import Phone from "@/components/modal/Phone";
import Auth from "@/components/modal/Auth";
import Account from "@/components/modal/Account";
import StatusWaiting from "@/components/modal/StatusWaiting";
import RecoveryPass from "@/components/modal/RecoveryPass";
import NeedMoreData from "@/components/modal/NeedMoreData";
import AccessApplication from "@/components/modal/AccessApplication";
import SmsPage from "@/components/modal/SmsPage";
import Back from "@/components/modal/Back.vue";
import Declined from "@/components/modal/Declined";
import CheckApplication from "@/components/modal/CheckApplication.vue";
import Registration from "@/components/modal/registration/Registration.vue";

export default {
    name: "Modals",

    props: {
        router: {
            type: Object,
        },
    },

    components: {
        Phone,
        Declined,
        AccessApplication,
        NeedMoreData,
        RecoveryPass,
        Account,
        Registration,
        Auth,
        StatusWaiting,
        SmsPage,
        Back,
        CheckApplication,
    },

    data: () => ({
        currentRoute: "",
        waitingData: {},
        merge: false,
    }),

    computed: {
        anketa() {
            return this.$root.anketa;
        },
    },

    watch: {
        templateAccount: function () {
            if (
                this.router.account ||
                this.router.auth ||
                this.router.recoveryPass
            ) {
                this.changeRouter("account", "account");
            } else {
                this.changeRouter(this.currentRoute, "back");
            }
        },
    },

    methods: {
        goNextStep(data) {
            console.log('go next step', data)
            this.currentRoute = data.next;
            if (data.merge) {
                this.merge = data.merge;
            }
            if (data.success === true) {
                this.changeRouter(data.prev, data.next);
            }
        },

        changeStatusAndTemplate(route, data) {
            this.currentRoute = route.nextPage;
            this.waitingData = data;

            for (const k in this.router) {
                if (k === route.nextPage) {
                    this.changeRouter(route.prevPage, route.nextPage);
                }
            }
        },

        changeRouter(prev, next) {
            if (prev.length != 0 && next.length != 0) {
                // if (this.resultStatus !== {}) {
                //   this.resultStatus?.clearInterval();
                // }

                for (const k in this.router) {
                    this.router[k] = false;
                }
                this.router[next] = true;
                this.router.history = prev;
            } else {
                return;
            }
        },
    },
};
</script>
