<template>
    <div>
        <div v-if="$root.appType('cash') || $root.appType('nordwind') || $root.appType('bankiros')" class="mb-8">
            <h4 v-if="title" class="block-title text-left mb-6">{{ title }}</h4>
            <FieldsCard :anketaNames="fields"/>
        </div>
        <div v-if="$root.appType('vtb')" class="mb-10">
            <h4 v-if="title" class="vtb_block_title text-left mb-8">{{ title }}</h4>
            <FieldsCard :anketaNames="fields"/>
        </div>
        <div v-if="$root.appType('travelata')" class="mb-10">
            <h4 v-if="title" class="travelata_block_title text-left mb-8">{{ title }}</h4>
            <FieldsCard :anketaNames="fields"/>
        </div>
    </div>
</template>

<script>
import FieldsCard from "@/components/FieldsCard";

export default {
  components: {FieldsCard},
  props: {
    fields: Array,
    title: String
  },

  methods: {

  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/_vtb-variables.scss";
@import "@/assets/scss/_travelata-variables.scss";

.vtb_block_title {
    @include vtb-form-title-paragraph;
    color: #2F3441;
}
.travelata_block_title {
    @include tr-text(20px, 600);
    font-family: 'OpenSansSB';
    color: #333;
}
</style>
