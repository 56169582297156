<template>
    <div class="mt-1">
        <div class="term number-information mb-4 ml-4">
            <slot>Срок займа {{ sliderValue }} {{ sliderValue | month }}</slot>
        </div>

        <v-slider
            v-model="sliderValue"
            :min="min"
            :max="max"
            :step="step"

            color="paylate-red"
            track-color="#bfbfbf"
            hide-details
            validate-on-blur
            >
        </v-slider>

        <div class="is-size-6 float-right pr-4" >{{comment}}</div>
    </div>
</template>

<script>
import month from "../helpers/serviceFunctions.js";

export default {
    props: {
        value: Number,
        min: Number,
        max: Number,
        step: Number,
        comment: String,
    },
    computed: {
        sliderValue: {
            get() {
                return this.value;
            },
            set(val) {
                this.$emit('input', val);
            }
        }
    },
    data: () => ({
        // sliderValue: 12,
    }),
    filters: {
        month,
    }
}
</script>

<style>
    .v-slider__track-container {
        overflow: hidden;
    }

</style>
